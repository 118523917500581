/** @jsxImportSource @emotion/react */

import { theme, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import {
  SignupLocation,
  ToProposalsLocation,
  ToSigninLocation,
} from "Locations";
import useCheckAccessModal from "components/AccessModalProvider/useCheckAccessModal";
import { useGlobalModals } from "components/GlobalModals";
import {
  BackButtonBehavior,
  default as LayoutHeader,
} from "components/Layout/Header";
import { BuildersReviewStage } from "components/Proposals";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import TrackButton from "components/TrackButton";
import config from "config";
import { zeroFrictionSignUpEnabled } from "configs/auth";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useMinBreakpoint } from "hooks/useMinBreakpoint";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { AdminReviewStatus, ProposalObject } from "models/Proposal";
import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRootStore } from "store";
import NoAccessModalContent from "views/Mission/Proposals/Native/NoAccessModalContent";
import { PublicUntilBanner } from "views/Mission/Proposals/Native/PublicUntilBanner";
import { copyToClipboard } from "../../../../helpers/clipboard";
import { useUpdatePublicUntil } from "../../../../queries/proposals/useUpdatePublicUntil";

const Header = ({
  enableInteractions = false,
  onAdminReviewProposalClick,
  onAdminShareProposalClick,
  onClientReviewProposalClick,
  pageTitle,
  proposal,
}: {
  enableInteractions?: boolean;
  onAdminReviewProposalClick?: (isApproval: boolean) => void;
  onAdminShareProposalClick?: () => void;
  onClientReviewProposalClick?: (stage?: BuildersReviewStage) => void;
  pageTitle: string;
  proposal: ProposalObject;
}) => {
  const {
    uiStore: { isMobile, setToast },
    userStore: { isAppInAdminMode, user: currentUser },
  } = useRootStore();
  const history = useHistory();
  const {
    setSelectedBuilderIds,
    setSelectedInterviewee,
    setInterviewModalMode,
    canBookInterviews,
  } = useProposalContext();
  const { toggleInterviewModal } = useGlobalModals();
  const { mutate: updatePublicUntil } = useUpdatePublicUntil(
    (data: ProposalObject) => {
      if (data.publicUntil) {
        copyToClipboard(proposal.publicURL);
      }

      setToast({
        text: data.publicUntil
          ? "Proposal URL has been copied to the clipboard"
          : "Proposal has been switched to private mode",
        type: "success",
      });
    }
  );
  const { search } = useLocation();

  const [isSolid, setIsSolid] = useState(false);

  const { checkAndHandlePermission } = useCheckAccessModal();

  const showApprovalActions = useMemo(() => {
    return (
      isAppInAdminMode &&
      !proposal.isSampleProposal &&
      (proposal?.adminReview || {}).status !== AdminReviewStatus.Approved
    );
  }, [isAppInAdminMode, proposal]);

  const proposalId = proposal?.id;

  const hasMultipleBuilders =
    proposal.roles.length > 1 || proposal.roles[0].builders.length > 1;

  const styles: CSSObject = useCSSRulesWithTheme(({ colors }) => ({
    feedbackButton: {
      "&&": {
        height: 40,
        borderColor: colors.Grey[0],
        color: isSolid ? colors.Grey[900] : colors.Grey[0],
        "&:hover": !isSolid && {
          borderColor: colors.Grey[0],
          color: colors.Grey[0],
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
      },
    },
    shareButton: {
      "&&": {
        borderColor: isSolid ? "transparent" : colors.Grey[0],
        borderStyle: "solid",
        borderWidth: 1,
        boxSizing: "border-box",
        color: isSolid ? colors.Grey[900] : colors.Grey[0],
        padding: "7px 20px",
        "&:hover": !isSolid && {
          borderColor: colors.Grey[0],
          color: colors.Grey[0],
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
        "& svg": {
          cursor: "pointer",
        },
      },
    },
    disclaimer: {
      background: colors.Grey[700],
      padding: "10px 12px",
      borderRadius: 4,
    },
  }));

  const desktopActions = useMemo(() => {
    if (!currentUser) {
      // Show login buttons
      return (
        <>
          <TrackButton
            name="Login"
            variant={isSolid ? "secondary" : "ghost"}
            style={{
              color: isSolid ? theme.colors.Grey[900] : theme.colors.Grey[0],
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: isSolid ? "transparent" : theme.colors.Grey[0],
            }}
            size="md"
            onClick={() => history.push(ToSigninLocation())}
          >
            Sign in
          </TrackButton>
          <TrackButton
            name="Signup"
            variant={isSolid ? "main" : "secondary"}
            onClick={() => {
              if (zeroFrictionSignUpEnabled) {
                history.push(SignupLocation);
              } else {
                window.location.href = config.REACT_APP_EXTERNAL_ONBOARDING_URL;
              }
            }}
            size="md"
            style={{
              border: "1px solid transparent",
            }}
          >
            Create an account
          </TrackButton>
        </>
      );
    }

    if (showApprovalActions) {
      return (
        <>
          <TrackButton
            name="Admin approve proposal"
            onClick={() =>
              onAdminReviewProposalClick && onAdminReviewProposalClick(true)
            }
            size={isMobile ? "sm" : "md"}
            variant="positive"
            data-testing-id="proposal-approve-button"
          >
            Approve
          </TrackButton>
          <TrackButton
            eventProps={{ proposalId }}
            name="Admin reject proposal"
            onClick={() =>
              onAdminReviewProposalClick && onAdminReviewProposalClick(false)
            }
            size={isMobile ? "sm" : "md"}
            variant="negative"
            data-testing-id="proposal-hide-button"
          >
            Hide
          </TrackButton>
        </>
      );
    }

    if (enableInteractions) {
      return (
        <>
          {proposal.isSampleProposal && (
            <Typography
              variant="textMedium"
              color="Grey@0"
              css={styles.disclaimer}
            >
              This is a sample proposal. Some builders listed may not be
              available for interviews.
            </Typography>
          )}
          {hasMultipleBuilders && !proposal.isSampleProposal && (
            <TrackButton
              disabled={!canBookInterviews}
              eventProps={{ proposalId }}
              name="Book interviews"
              onClick={async () => {
                const hasPermission = await checkAndHandlePermission(
                  {
                    title: "Request access",
                    content: <NoAccessModalContent />,
                    forMissionSpecId: proposal?.missionSpecId,
                  },
                  () => canBookInterviews
                );

                if (hasPermission) {
                  setSelectedInterviewee(undefined);
                  setInterviewModalMode("multiple");
                  toggleInterviewModal(true);
                }
              }}
              size={isMobile ? "sm" : "md"}
              variant={isSolid ? "main" : "secondary"}
            >
              Book interviews
            </TrackButton>
          )}
          {!isAppInAdminMode && !proposal.isSampleProposal && (
            <TrackButton
              disabled={!canBookInterviews}
              eventProps={{ proposalId }}
              name="Provide feedback"
              onClick={async () => {
                const hasPermission = await checkAndHandlePermission(
                  {
                    title: "Request access",
                    content:
                      "You must be a member of this workspace to provide feedback.",
                    forMissionSpecId: proposal?.missionSpecId,
                  },
                  () => canBookInterviews
                );

                if (hasPermission) {
                  setSelectedBuilderIds([]);
                  onClientReviewProposalClick &&
                    onClientReviewProposalClick(BuildersReviewStage.FEEDBACK);
                }
              }}
              size={isMobile ? "sm" : "md"}
              variant={isSolid ? "secondary" : "ghost"}
              css={styles.feedbackButton}
            >
              Provide feedback
            </TrackButton>
          )}
          {isAppInAdminMode &&
            (proposal.isSampleProposal ||
              proposal.adminReview?.status === AdminReviewStatus.Approved) && (
              <PublicUntilBanner
                publicUntil={
                  proposal.publicUntil
                    ? DateTime.fromISO(
                        proposal.publicUntil.toLocaleString()
                      ).toJSDate()
                    : undefined
                }
                onToggle={(sharePublicly) =>
                  updatePublicUntil({
                    proposalId,
                    isSampleProposal: proposal.isSampleProposal,
                    sharePublicly,
                    params: search,
                  })
                }
              />
            )}
          {isAppInAdminMode && !proposal.isSampleProposal && (
            <TrackButton
              name="Share & manage proposal"
              aria-label="share proposal"
              css={styles.shareButton}
              onClick={onAdminShareProposalClick}
              size="sm"
              variant={isSolid ? "secondary" : "ghost"}
              iconProps={{
                name: "share",
                margin: "none",
                size: "lg",
                style: { cursor: "pointer" },
              }}
              data-testing-id="proposal-share-button"
            >
              Share
            </TrackButton>
          )}
        </>
      );
    }
  }, [
    isSolid,
    onAdminShareProposalClick,
    onClientReviewProposalClick,
    proposalId,
    showApprovalActions,
    styles,
    canBookInterviews,
  ]);

  const mobileActions = useMemo(() => {
    if (
      !showApprovalActions &&
      enableInteractions &&
      isAppInAdminMode &&
      !proposal.isSampleProposal
    ) {
      return (
        <TrackButton
          name="Share proposal"
          aria-label="share proposal"
          css={styles.shareButton}
          onClick={onAdminShareProposalClick}
          size="sm"
          variant={isSolid ? "secondary" : "ghost"}
          iconProps={{
            name: "share",
            margin: "none",
            size: "md",
            style: { cursor: "pointer" },
          }}
        >
          Share
        </TrackButton>
      );
    }
  }, [
    isSolid,
    onAdminShareProposalClick,
    showApprovalActions,
    enableInteractions,
    styles,
  ]);

  const isDesktop = useMinBreakpoint("sm");

  return (
    <LayoutHeader
      pageTitle={!isDesktop && isSolid ? pageTitle : ""}
      backButtonLocation={
        proposal.missionSpecId
          ? ToProposalsLocation(proposal.missionSpecId)
          : undefined
      }
      backButtonBehavior={BackButtonBehavior.GO_BACK}
      backButtonText="Back to team assembly"
      backButtonColor={isSolid ? theme.colors.Grey[900] : theme.colors.Grey[0]}
      hideAvatars={true}
      hideBackButton={
        (!enableInteractions && !isAppInAdminMode) || proposal.isSampleProposal
      }
      solidOnScroll={true}
      onSolid={(solid) => setIsSolid(solid)}
      ctaBlock={isDesktop ? desktopActions : mobileActions}
      useWhiteArrow={true}
    />
  );
};

export default observer(Header);
