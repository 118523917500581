import { DataLoader } from "loaders";
import MissionSpec from "../models/MissionSpec";

import { getMissionSpec } from "services/missionSpec";

const missionStoreLoader: DataLoader<{ mid: string }> = async (
  {
    missionSpecStore,
    authStore,
    uiStore: { setThenable },
    errorStore: { addError },
  },
  match
) => {
  if (missionSpecStore.usingV3) {
    missionSpecStore.setLoading(false);
    return;
  }

  missionSpecStore.setLoading(true);

  if (
    !missionSpecStore.roleCategories ||
    !missionSpecStore.roleCategories.length
  ) {
    missionSpecStore.loadRoleCategories();
  }

  const {
    isExact,
    params: { mid },
  } = match;

  if (mid === "new") {
    if (isExact && !missionSpecStore.missionSpec) {
      missionSpecStore.newMission();
    }
    missionSpecStore.setLoading(false);
    return;
  }

  // @note: this block can be deleted once we use useMissionSpec everywhere
  if (
    !missionSpecStore.missionSpec ||
    missionSpecStore.missionSpec.mid !== mid
  ) {
    try {
      missionSpecStore.unsetMission();
      const promise = mid && getMissionSpec(authStore, mid, true);

      promise && setThenable(promise);

      const missionSpec = (await promise) as MissionSpec;

      if (missionSpec) {
        missionSpecStore.setMission(missionSpec);
        missionSpecStore.preserveServerVersionMissionSpec(missionSpec);
      }
    } catch (err) {
      addError(err as Error);
    } finally {
      missionSpecStore.setLoading(false);
    }
  }
};

export default missionStoreLoader;
