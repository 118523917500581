import { getInterviewsRestrictions } from "services/scheduling";
import { useQuery } from "@tanstack/react-query";
import { useRootStore } from "store";

const useGetInterviewRestrictions = (roleCategoryId: string) => {
  const {
    authStore,
    accountsStore: { currentAccountId },
  } = useRootStore();

  return useQuery({
    enabled: !!roleCategoryId && authStore.isLoggedIn && !!currentAccountId,
    queryKey: ["interviewRestrictions", roleCategoryId],
    queryFn: () => getInterviewsRestrictions(authStore, { roleCategoryId }),
  });
};

export default useGetInterviewRestrictions;
