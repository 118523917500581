import ContractObject from "@a_team/models/dist/ContractObject";
import { InvoiceObject } from "@a_team/models/dist/InvoiceObject";
import {
  TimesheetSummaryFeedbackAction,
  TimesheetSummaryFeedbackObject,
  TimesheetSummaryType,
} from "@a_team/models/dist/TimesheetSummaryFeedbackObject";
import {
  BasicMissionPaymentCycleAdminObject,
  BasicMissionPaymentCycleObject,
} from "@a_team/models/dist/MissionPaymentCycleObject";
import {
  TalentCategory,
  TalentSkill,
  TalentSpecialization,
} from "@a_team/models/dist/TalentCategories";
import { TalentIndustry } from "@a_team/models/dist/TalentIndustry";
import { TeamPulse } from "@a_team/models/dist/TeamPulse";
import { QueryNextToken, QueryResult } from "@a_team/models/dist/misc";
import { FileInfo } from "@uploadcare/react-widget";
import { IChatPayload } from "models/Chatbot";
import MissionSpec, {
  MissionSpecAssets,
  MissionSpecCard,
  MissionSpecPublishPayload,
  Role,
  toMissionDbRefs,
  toRoleDbRefs,
} from "models/MissionSpec";
import MissionObject from "@a_team/models/dist/MissionObject";
import serviceFetch, { serviceFetchParameters } from "services/helpers";
import { AuthStore } from "store/Auth";
import RoleCategory from "models/RoleCategory";
import { ParsedUrlQueryInput } from "querystring";
import { Role as AccountRole } from "../configs/role";
import { TimesheetInitiativeObject } from "@a_team/models/dist/TimesheetInitiativeObject";
import { TimesheetId } from "@a_team/models/dist/TimesheetObject";

function handleAbortErrorFallback<T>(error: Error, fallbackValue: T): T {
  const ABORT_ERROR = "AbortError";

  if (error.name === ABORT_ERROR) {
    return fallbackValue;
  }

  throw error;
}

export const getRoleCategories = async (
  auth: AuthStore
): Promise<Array<RoleCategory>> =>
  serviceFetch(auth, "proxy/role-categories", null, "get");

export const getTalentSpecializations = async (
  auth: AuthStore
): Promise<Array<TalentSpecialization>> =>
  serviceFetch(auth, "proxy/talent-specializations", null, "get");

export const getTalentIndustries = async (
  auth: AuthStore,
  search: string | null,
  next: string | null
): Promise<{ items: Array<TalentIndustry>; next: string | null }> =>
  serviceFetch(auth, "proxy/talent-industries", { n: next, s: search }, "get");

export const getTalentSkills = async (
  auth: AuthStore,
  search: string | null,
  next: string | null,
  talentCategoryId: string[]
): Promise<{ items: Array<TalentSkill>; next: string | null }> => {
  return serviceFetch(
    auth,
    "proxy/talent-skills",
    { n: next, s: search, c: talentCategoryId },
    "get"
  );
};

export const getRelevantSkills = async (
  auth: AuthStore,
  next: string | null,
  talentCategoryId: string
): Promise<Array<TalentSkill>> => {
  return serviceFetch(auth, "skills", { cid: talentCategoryId }, "get");
};

export const getTalentCategories = async (
  auth: AuthStore
): Promise<Array<TalentCategory>> => {
  return serviceFetch(auth, "skills/categories", null, "get");
};

export const createTalentSkill = async (
  auth: AuthStore,
  skill: string,
  talentCategoryId: string[]
): Promise<TalentSkill> =>
  serviceFetch(auth, "proxy/talent-skills", null, "post", {
    name: skill,
    talentCategoryId,
  });

export const getUserMissionSpecs = async (
  auth: AuthStore,
  search: string | null,
  next: string | null,
  signal?: AbortSignal
): Promise<{ items: Array<MissionSpecCard>; next: string | null }> =>
  serviceFetch<{ items: Array<MissionSpecCard>; next: string | null }>(
    auth,
    "mission-specs/v2/non-running",
    { next, search },
    "get",
    null,
    undefined,
    { signal }
  ).catch((err) => handleAbortErrorFallback(err, { items: [], next: null }));

export const getUserRunningMissionSpecs = async (
  auth: AuthStore,
  search: string | null,
  next: string | null,
  signal?: AbortSignal
): Promise<{ items: Array<MissionSpecCard>; next: string | null }> =>
  serviceFetch<{ items: Array<MissionSpecCard>; next: string | null }>(
    auth,
    "mission-specs/v2/running",
    { next, search },
    "get",
    null,
    undefined,
    { signal }
  ).catch((err) => handleAbortErrorFallback(err, { items: [], next: null }));

export const getMissionSpec = async (
  auth: AuthStore,
  id: string,
  roles: boolean = false,
  signal?: AbortSignal
) => {
  return serviceFetch<MissionSpec | null>(
    auth,
    `mission-specs/${id}?roles=${roles}`,
    null,
    "get",
    null,
    undefined,
    { signal }
  ).catch((err) => handleAbortErrorFallback(err, null));
};

export const getMissionSpecV2 = async (
  auth: AuthStore,
  id: string,
  roles: boolean = false,
  signal?: AbortSignal
) => {
  return serviceFetch<MissionSpec | null>(
    auth,
    `v2/mission-specs/${id}?roles=${roles}`,
    null,
    "get",
    null,
    undefined,
    { signal }
  ).catch((err) => handleAbortErrorFallback(err, null));
};

export const getMissionInvoices = async (
  auth: AuthStore,
  platformId: string,
  next?: QueryNextToken
): Promise<QueryResult<InvoiceObject>> => {
  return serviceFetch(
    auth,
    `proxy/missions/${platformId}/invoices`,
    { next },
    "get"
  );
};

export const getMissionPaymentCycles = async (
  auth: AuthStore,
  mid: string
): Promise<
  QueryResult<
    BasicMissionPaymentCycleObject | BasicMissionPaymentCycleAdminObject
  >
> => serviceFetch(auth, `proxy/missions/${mid}/payment-cycles`, null, "get");

export const getTimesheetInitiatives = async (
  auth: AuthStore,
  mid: string,
  timesheetId: string
): Promise<TimesheetInitiativeObject[]> =>
  serviceFetch(
    auth,
    `proxy/missions/${mid}/timesheets/${timesheetId}/initiatives`,
    null,
    "get"
  );

export const getMissionPaymentCycle = async (
  auth: AuthStore,
  mid: string,
  yid: string
): Promise<
  BasicMissionPaymentCycleObject | BasicMissionPaymentCycleAdminObject
> =>
  serviceFetch(
    auth,
    `proxy/missions/${mid}/payment-cycles/${yid}`,
    null,
    "get"
  );

export const getMissionContracts = async (
  auth: AuthStore,
  platformId: string,
  next?: QueryNextToken
): Promise<QueryResult<ContractObject>> => {
  return serviceFetch(
    auth,
    `proxy/missions/${platformId}/contracts`,
    { next },
    "get"
  );
};

export const getPlatformAgreements = async (
  auth: AuthStore
): Promise<ContractObject[]> => {
  return serviceFetch(auth, `contracts/platform-agreements`, null, "get");
};

export const getMissionTeamPulse = async (
  auth: AuthStore,
  platformId: string
): Promise<TeamPulse[]> => {
  return serviceFetch(
    auth,
    `proxy/missions/${platformId}/team-pulse`,
    null,
    "get"
  );
};

export const createMissionSpec = async (
  auth: AuthStore,
  body: MissionSpec,
  signal?: AbortSignal
): Promise<MissionSpec> =>
  serviceFetch<MissionSpec>(
    auth,
    `mission-specs`,
    null,
    "post",
    toMissionDbRefs(body),
    undefined,
    { signal }
  ).catch((err) => handleAbortErrorFallback(err, body));

export const createMissionSpecV2 = async (
  auth: AuthStore,
  body: MissionSpec,
  signal?: AbortSignal
): Promise<MissionSpec> =>
  serviceFetch<MissionSpec>(
    auth,
    `v2/mission-specs`,
    null,
    "post",
    toMissionDbRefs(body, undefined, true),
    undefined,
    { signal }
  ).catch((err) => handleAbortErrorFallback(err, body));

export const updateMissionSpec = async (
  auth: AuthStore,
  mid: string,
  body: MissionSpec,
  serverVersionMissionSpec: MissionSpec
): Promise<MissionSpec> =>
  serviceFetch(
    auth,
    `mission-specs/${mid}`,
    null,
    "put",
    toMissionDbRefs(body, serverVersionMissionSpec)
  );

export const updateMissionSpecV2 = async (
  auth: AuthStore,
  mid: string,
  body: Partial<MissionSpec>
): Promise<MissionSpec> =>
  serviceFetch(auth, `v2/mission-specs/${mid}`, null, "put", body);

export const publishMission = async (
  auth: AuthStore,
  body: MissionSpec
): Promise<MissionSpec> =>
  serviceFetch(auth, `mission-specs/${body.mid}/publish`, null, "post", body);

export const publishMissionV2 = async (
  auth: AuthStore,
  body: MissionSpecPublishPayload
): Promise<MissionObject> =>
  serviceFetch(auth, `v2/mission-specs/${body.mid}/publish`, null, "post", {
    hubspotDealId: body.hubspotDealId,
  });

export const confirmSpec = async (
  auth: AuthStore,
  mid: string
): Promise<{ updated: MissionSpec }> =>
  serviceFetch(auth, `mission-specs/${mid}/confirm`, null, "post");

export const confirmSpecV2 = async (
  auth: AuthStore,
  mid: string
): Promise<{ updated: MissionSpec }> =>
  serviceFetch(auth, `v2/mission-specs/${mid}/confirm`, null, "post");

export const deleteMissionSpec = async (
  auth: AuthStore,
  mid: string
): Promise<void> => serviceFetch(auth, `mission-specs/${mid}`, null, "delete");

export const createRoleRevision = async (
  auth: AuthStore,
  mid: string,
  body: Role
): Promise<{ doc: Role }> =>
  serviceFetch(auth, `roles/${mid}`, null, "post", toRoleDbRefs(body));

export const updateRoleRevision = async (
  auth: AuthStore,
  mid: string,
  rid: string,
  body: Role
): Promise<{ doc: Role }> =>
  serviceFetch(auth, `roles/${mid}/${rid}`, null, "put", toRoleDbRefs(body));

export const approveRoleRevision = async (
  auth: AuthStore,
  mid: string,
  rid: string,
  body: Role
): Promise<MissionSpec> =>
  serviceFetch(
    auth,
    `roles/${mid}/${rid}/approve`,
    null,
    "post",
    toRoleDbRefs(body)
  );

export const declineRoleRevision = async (
  auth: AuthStore,
  mid: string,
  rid: string,
  body: {
    rejectionCategory: string;
    rejectionDetails?: string;
  }
): Promise<{ doc?: Role }> =>
  serviceFetch(auth, `roles/${mid}/${rid}/decline`, null, "post", body);

export const deleteRoleRevision = async (
  auth: AuthStore,
  mid: string,
  rid: string
): Promise<void> => serviceFetch(auth, `roles/${mid}/${rid}`, null, "delete");

export const revokeMissionRolesForUser = async (
  auth: AuthStore,
  missionId: string,
  userId: string
): Promise<void> => {
  return serviceFetch(
    auth,
    `mission-specs/${missionId}/revoke-roles`,
    null,
    "post",
    {
      userId,
    }
  );
};

export const processVideoUpload = async (
  auth: AuthStore,
  mid: string,
  info: FileInfo
): Promise<void> =>
  serviceFetch(auth, `mission-specs/${mid}/process-video`, null, "post", info);

export const updateMission = async (
  auth: AuthStore,
  mid: string,
  body: MissionSpec
): Promise<MissionSpec> =>
  serviceFetch(
    auth,
    `mission-specs/${mid}/mission`,
    null,
    "put",
    toMissionDbRefs(body)
  );

export const getMissionSpecAssets = async (
  auth: AuthStore,
  mid: string
): Promise<MissionSpecAssets> =>
  serviceFetch(auth, `mission-specs/${mid}/assets`, null, "get");

export const createMissionSpecFromChatbot = async (
  auth: AuthStore,
  chatbotPayload: IChatPayload
): Promise<MissionSpec> =>
  serviceFetch(
    auth,
    `mission-specs/from-chatbot`,
    null,
    "post",
    chatbotPayload
  );

export const addMissionCollaborator = async (
  auth: AuthStore,
  mid: string,
  body: {
    email: string;
    firstName: string;
    lastName: string;
    role: AccountRole;
    skipEmail?: boolean;
  },
  query?: ParsedUrlQueryInput
): Promise<MissionSpec> =>
  serviceFetch(auth, `mission-specs/${mid}/invite`, query, "post", body);

export const getLatestMissionTitles = async (
  auth: AuthStore
): Promise<
  {
    title: string;
    mid: string;
    createdAt: string;
    updatedAt: string;
    specStatus: string;
    missionStatus?: string;
  }[]
> => serviceFetch(auth, `mission-specs/titles`, null, "get");

export const getRequestedMissionCount = async (
  auth: AuthStore
): Promise<number> =>
  serviceFetch(auth, `mission-specs/requested-count`, null, "get");

export const updateTeamSummary = async (
  auth: AuthStore,
  mid: string,
  pcid: string,
  body: {
    teamSummary: string;
    teamSummaryHtml: string;
  }
): Promise<void> =>
  serviceFetch(
    auth,
    `proxy/missions/${mid}/payment-cycles/${pcid}/update-team-summary`,
    null,
    "post",
    body
  );

export const updateBuilderSummary = async (
  auth: AuthStore,
  mid: string,
  timesheetId: string,
  body: {
    builderSummary: string;
    builderSummaryHtml: string;
  }
): Promise<void> =>
  serviceFetch(
    auth,
    `proxy/missions/${mid}/timesheets/${timesheetId}/update-builder-summary`,
    null,
    "post",
    body
  );

export const createSummaryFeedback = async (
  auth: AuthStore,
  mid: string,
  pcid: string,
  body: {
    type: TimesheetSummaryType;
    action: TimesheetSummaryFeedbackAction;
    timesheetId?: TimesheetId;
    response?: string;
  }
): Promise<void> =>
  serviceFetch(
    auth,
    `proxy/missions/${mid}/payment-cycles/${pcid}/create-summary-feedback`,
    null,
    "post",
    body
  );

export const getSummaryFeedback = async (
  auth: AuthStore,
  mid: string,
  pcid: string
): Promise<TimesheetSummaryFeedbackObject[]> =>
  serviceFetch(
    auth,
    `proxy/missions/${mid}/payment-cycles/${pcid}/summary-feedback`,
    null,
    "get"
  );

export const exportTeamSummariesToPDF = async (
  auth: AuthStore,
  mid: string,
  pcid: string,
  timesheetId?: string
): Promise<Blob> => {
  const response = await fetch(
    ...serviceFetchParameters(
      auth,
      `proxy/missions/${mid}/payment-cycles/${pcid}/export-team-summaries-to-pdf`,
      null,
      "post",
      {
        timesheetId,
      }
    )
  );

  if (!response.ok) {
    throw new Error("Failed to generate PDF");
  }

  return await response.blob();
};
