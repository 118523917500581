/** @jsxImportSource @emotion/react */

import { Button, Icon, Modal, Typography } from "@a_team/ui-components";
import { SignupLocation, ToSigninLocation } from "Locations";
import analytics from "analytics";
import { UNAUTHORIZED_BOOK_INTERVIEW_CLICKED } from "analytics/events";
import { BookDirectInterviewModal } from "components/BookDirectInterviewModal";
import { useGlobalModals } from "components/GlobalModals";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import { BOOK_INTERVIEW_MISSION_READ_ACCESS_REQUIRED } from "components/Proposals/ReviewBuilders/constants";
import TrackButton from "components/TrackButton";
import TrackTooltip from "components/TrackTooltip";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useIsMobile } from "hooks/useIsMobile";
import { ProposalRoleBuilder } from "models/Proposal";
import { TargeterUserCard } from "models/User";
import { useInterviewBookings } from "queries/interview/useInterviewBookings";
import useGetLinkedProposals from "queries/proposals/useGetLinkedProposals";
import useProposal from "queries/proposals/useProposal";
import useGetInterviewRestrictions from "queries/scheduling/useGetInterviewRestrictions";
import { useUserProfileByUserId } from "queries/users/useUserProfileByUserId";
import {
  CSSProperties,
  FC,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { UserObjectOverride } from "services/searchServiceService";
import { useRootStore } from "store";

export type BookInterviewUser = TargeterUserCard | UserObjectOverride;

export interface BookInterviewButtonProps {
  user: BookInterviewUser;
  style?: CSSProperties;
  onClick?(): void;
  className?: string;
  disabled?: boolean;
  labelMobile?: string;
  labelDesktop?: string;
}

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  button: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.Grey[200],
    padding: "12px 16px",
    borderRadius: 4,
    "& > span": {
      display: "flex",
      alignItems: "center",
      gap: 4,
      lineHeight: "16px",
    },
  },
  tooltip: {
    textAlign: "center",
  },
  modalHeader: {
    fontSize: 18,
    lineHeight: "26px",
    marginTop: 0,
    marginBottom: 4,
  },
  authButtons: {
    display: "flex",
    gap: 12,
    marginTop: 20,
    justifyContent: "flex-end",
    "& button": {
      padding: "10px 24px",
      borderRadius: 4,
    },
  },
});

export const BookInterviewButton: FC<BookInterviewButtonProps> = ({
  style,
  user,
  onClick,
  className,
  disabled,
  labelMobile = "Book",
  labelDesktop = "Book an interview",
}) => {
  const isMobile = useIsMobile();
  const styles = useCSSRulesWithTheme(getCSSRules);
  const history = useHistory();
  const {
    authStore: { isLoggedIn },
  } = useRootStore();

  const { data: userProfile } = useUserProfileByUserId(user.uid);
  const { data: interviewRestrictions } = useGetInterviewRestrictions(
    userProfile?.talentProfile?.talentSpecializations?.mainTalentSpecialization
      ?.id || ""
  );

  const { data: linkedProposals } = useGetLinkedProposals(user.uid);
  const { data: proposal } = useProposal(linkedProposals?.[0]?._id);
  // data has to be loaded to update queryKeys.interview.list after interview is booked
  useInterviewBookings(proposal?.id || "");

  const { setCurrentProposal, setSelectedInterviewee, canBookInterviews } =
    useProposalContext();
  const { toggleInterviewModal } = useGlobalModals();

  const [isBookInterviewModalOpen, setIsBookInterviewModalOpen] =
    useState(false);
  const [isNotAvailableDrawerOpen, setIsNotAvailableDrawerOpen] =
    useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const [userHasInterview, setUserHasInterview] = useState(
    !!user.everHadAnInterviewWithClient
  );
  useEffect(() => {
    if (user?.everHadAnInterviewWithClient) {
      setUserHasInterview(true);
    }
  }, [user?.everHadAnInterviewWithClient]);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    if (!isLoggedIn) {
      setIsAuthModalOpen(true);
      analytics.track(UNAUTHORIZED_BOOK_INTERVIEW_CLICKED, {
        builderId: user.uid,
      });
    } else if (proposal) {
      setCurrentProposal(proposal);
      setSelectedInterviewee(user as ProposalRoleBuilder);
      toggleInterviewModal(true);
    } else {
      setIsBookInterviewModalOpen(true);
      onClick && onClick();
    }
  };

  const hitMaxBookings =
    interviewRestrictions && !interviewRestrictions?.canBookInterviews;

  const isBookingDisabled = disabled || hitMaxBookings || !canBookInterviews;

  const tooltipText = useMemo(() => {
    if (!canBookInterviews) {
      return BOOK_INTERVIEW_MISSION_READ_ACCESS_REQUIRED;
    }

    if (!isBookingDisabled) {
      return "";
    }

    if (hitMaxBookings) {
      return "You reached the maximum number of interview bookings for this role.";
    }

    return isLoggedIn
      ? "This builder cannot be booked because they have not shared their calendar link."
      : "Please log in to book an interview.";
  }, [
    interviewRestrictions,
    isLoggedIn,
    isBookingDisabled,
    canBookInterviews,
    hitMaxBookings,
  ]);

  return (
    <>
      <TrackTooltip
        text={tooltipText}
        disabled={!isBookingDisabled || isMobile}
        css={styles.tooltip}
      >
        <span
          onClick={
            isMobile && isBookingDisabled
              ? () => setIsNotAvailableDrawerOpen(true)
              : undefined
          }
        >
          <TrackButton
            name="BookInterviewButton"
            eventProps={{ builderId: user.uid }}
            className={className}
            css={styles.button}
            style={style}
            onClick={handleClick}
            disabled={isBookingDisabled}
          >
            {userHasInterview && (
              <Icon name="statusPositiveNoBorder" color="Grey@0" size="md" />
            )}
            {isMobile ? labelMobile : labelDesktop}
          </TrackButton>
        </span>
      </TrackTooltip>
      {isMobile && (
        <Modal
          isOpen={isNotAvailableDrawerOpen}
          variant="slideUp"
          onClose={() => setIsNotAvailableDrawerOpen(false)}
        >
          <Typography variant="h3" css={styles.modalHeader}>
            Not available for booking
          </Typography>
          <Typography variant="textMedium">
            This builder cannot be booked because they have not shared their
            calendar link.
          </Typography>
        </Modal>
      )}

      <Modal isOpen={isAuthModalOpen} onClose={() => setIsAuthModalOpen(false)}>
        <Typography variant="h3" css={styles.modalHeader}>
          Book an interview with {user.fullName}
        </Typography>
        <Typography variant="textMedium">
          If you want to book an interview with a selected builder, please sign
          in or create an account.
        </Typography>
        <div css={styles.authButtons}>
          <Button
            onClick={() => history.push(ToSigninLocation())}
            variant="secondary"
          >
            Sign in
          </Button>
          <Button onClick={() => history.push(SignupLocation)}>
            Create an account
          </Button>
        </div>
      </Modal>

      <BookDirectInterviewModal
        user={user}
        isOpen={isBookInterviewModalOpen}
        onClose={() => setIsBookInterviewModalOpen(false)}
        onInterviewBooked={() => setUserHasInterview(true)}
        minRate={interviewRestrictions?.minRate}
      />
    </>
  );
};
