/** @jsxImportSource @emotion/react */

import { UserReviewObject } from "models/UserReviews";
import { Avatar, Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";

interface Props {
  review: UserReviewObject;
}

export const getCSSRulesForRecommendation: CSSRulesResolver = ({ colors }) => ({
  container: {
    display: "flex",
    gap: 24,
  },
  logoColumn: {
    flexShrink: 0,
  },
  contentColumn: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  details: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    flexWrap: "wrap",
  },
  divider: {
    width: 1,
    height: 12,
    backgroundColor: colors.Grey[300],
  },
});

export const Recommendation = (props: Props) => {
  const styles = useCSSRulesWithTheme(getCSSRulesForRecommendation);
  const { review } = props;

  return (
    <div css={styles.container}>
      <div css={styles.logoColumn}>
        <Avatar src={review.companyLogo} size="md" />
      </div>
      <div css={styles.contentColumn}>
        <div css={styles.header}>
          <Typography variant="textLarge" weight={"bold"}>
            Feedback from {review.companyName}
          </Typography>
          <div css={styles.details}>
            {review.industry && (
              <>
                <Typography variant="textSmall" color="Grey@600">
                  {review.industry}
                </Typography>
                <div css={styles.divider} />
              </>
            )}
            <Typography variant="textSmall" color="Grey@600">
              Worked as a {review.role}
            </Typography>
            <div css={styles.divider} />
            <Typography variant="textSmall" color="Grey@600">
              {review.period}
            </Typography>
          </div>
        </div>
        {review.publicFeedback && (
          <Typography
            variant="textMedium"
            style={{
              whiteSpace: "pre-line",
            }}
          >
            {review.publicFeedback}
          </Typography>
        )}
      </div>
    </div>
  );
};
