import {
  BasicExperienceObject,
  JobExperienceData,
  ProjectExperienceData,
} from "@a_team/models/dist/ExperienceObject";
import LocationObject from "@a_team/models/dist/LocationObject";
import { CustomQuestionReply } from "@a_team/models/dist/MissionApplicationObject";
import { ClientRoleQuestion } from "@a_team/models/dist/MissionRole";
import {
  ProposalBasicObject,
  TeamNarrative,
} from "@a_team/models/dist/ProposalObject";
import RoleCategoryObject from "@a_team/models/dist/RoleCategory";
import {
  TalentSkill,
  TalentSkillId,
} from "@a_team/models/dist/TalentCategories";
import { UserCardObject, UserId } from "@a_team/models/dist/UserObject";
import { WorkingHoursSchemaObject } from "@a_team/models/dist/WorkingHoursObject";
import { DateISOString } from "@a_team/models/dist/misc";
import { Interview } from "models/Interview";
import { AccountUser } from "models/User";
import { CompanyObject } from "./ClientCompany";
import { UserReviewObject } from "./UserReviews";
import { LinkedInRecommendationObject } from "models/LinkedInRecommendations";

export enum ClientReviewStatus {
  NotifiedForInterview = "NotifiedForInterview",
  Rejected = "Rejected",
}

export enum ClientInterviewStatus {
  InterviewRequested = "InterviewRequested",
  InterviewAccepted = "InterviewAccepted",
  InterviewRejected = "InterviewRejected",
  InterviewCancelled = "InterviewCancelled",
  InterviewExpired = "InterviewExpired",
}

export enum AdminReviewStatus {
  Approved = "approved",
  Rejected = "rejected",
  Pending = "pending",
}

export interface ProposalReview {
  status: ClientReviewStatus | AdminReviewStatus;
  rejectionReason?: BuilderRejectionReason | AdminRejectionReason;
  rejectionNote?: string;
  reviewedAt: Date;
  reviewedBy: string;
}

export interface ProposalRoleBuilder extends UserCardObject {
  bestFit: boolean;
  clientReview?: ProposalReview;
  customQuestionsReplies?: CustomQuestionReply[];
  cvURL?: string;
  experiences?: {
    jobs: (BasicExperienceObject & JobExperienceData)[];
    projects: (BasicExperienceObject & ProjectExperienceData)[];
  };
  github?: {
    username?: string;
  };
  /** Based on application builder rate, with margins calcualted in  */
  clientDisplayRate?: number;
  clientMonthlyDisplayRate?: number;
  linkedin?: {
    username?: string;
  };
  location?: LocationObject;
  pitch?: string;
  hoursAvailablePerWeek?: number;
  tfsPitch?: {
    blurb: string;
    website: string;
  };
  blurb?: string;
  skills?: TalentSkillId[];
  yearsExperience?: number;
  skillsOverlap?: {
    id: string;
    name: string;
  }[];
  interviews?: Interview[];
  websites?: string[];
  yearsOfExperience?: number;
  showMonthlyRate?: boolean;
  showHourlyRate?: boolean;
  reviews?: UserReviewObject[];
  linkedInRecommendations?: LinkedInRecommendationObject[];
  portfolio?: {
    url: string;
    password?: string;
    hasPassword: boolean;
  };
}

/*
  Based on SampleProposalObject from the `models` package
  TODO
*/
export interface ProposalRole {
  builders: ProposalRoleBuilder[];
  roleTitle: string;
  customQuestions?: ClientRoleQuestion[];
  description: string;
  locations?: string[];
  minimumCommitment?: number;
  requiredSkills: TalentSkill[];
  rid: string;
  roleCategory: RoleCategoryObject;
  workingHours?: WorkingHoursSchemaObject;
  workingHoursNumberOfMinutesOverlap?: number;
}

export interface ProposalObject extends ProposalBasicObject {
  accountId?: string;
  adminReview?: ProposalReview;
  createdAt: DateISOString;
  createdBy: AccountUser;
  currency: "EUR" | "USD";
  id: string;
  isShared: boolean;
  logo?: string;
  missionId: string;
  missionSpecAuthor?: AccountUser;
  missionSpecId?: string;
  projectName: string;
  publicUntil?: DateISOString;
  publicURL: string;
  roles: ProposalRole[];
  teamBlurb?: string;
  teamNarratives?: TeamNarrative[];
  teamProposal?: boolean;
  version?: number;
  isSampleProposal?: boolean;
  rolesGroupedBy?: "rid" | "roleCategory";
}

export interface ProposalSummaryBuilder {
  id: string;
  profilePicture: string;
  fullName: string;
  location: LocationObject;
  title: string;
  interviewStatus?: string;
  interviews: Interview[];
  username: string;
  clientDisplayRate?: number;
}

export interface ProposalSummary {
  id: string;
  createdAt: string;
  publicURL: string;
  status: string;
  builders: ProposalSummaryBuilder[];
  version?: number;
}

export enum BuilderRejectionReason {
  missionScopeChanged = "I no longer need this role",
  noRequiredExperience = "This builder doesn't have the required experience",
  missingRequiredSkills = "This builder was missing must-have skills",
  outOfBudgetRange = "This builder was out of my budget range",
  noTimezoneOverlap = "This builder wasn't available in my timezone",
  other = "Other",
}

export interface BuilderReviewFeedback {
  uid: UserId;
  rejectionReason: BuilderRejectionReason;
  rejectionNote?: string;
}

export enum AdminRejectionReason {
  dontRecommendSpecificBuilder = "I don't recommend a specific builder",
  noMatchingSkills = "The builders do not match the role or skills",
  tooManyBuilders = "There are too many builders",
  incompleteProposal = "Team is incomplete and missing some roles",
  missionCancelled = "Mission is cancelled",
  editsAfterSharing = "Edits need to be made before sharing",
  other = "Other",
}

// When we submit an AdminReview, we can _implicitly create a new client user_ by sharing the proposal with a user that doesn't exist yet.
// This is how we describe that new user to the server
export type NewClientManagerAtShareTimeDescriptor = {
  uid: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  company?: Partial<CompanyObject>;
};

export interface AdminReview {
  status: AdminReviewStatus;
  rejectionReason?: AdminRejectionReason;
  selectedBDOwner?: string;
  /**
   * Array of uids of the client users we want to email about the proposal review
   */
  clientManagersToNotify?: string[];
  /**
   * Array of objects describing the client users we want to create in the db and notify
   */
  clientManagersToCreate?: NewClientManagerAtShareTimeDescriptor[];
  source?: string;
  version?: number;
}

export interface ProposalIdSummary {
  all: string[];
  adminPending: string[]; // Waiting for admin to review
  adminApproved: string[]; // Admin has approved, no matter if there are client reviews or not
  adminRejected: string[]; // The admin rejected. These won't get shown to clients
  clientPending: string[]; // Means the client has not reviewed any builders
}

export type TeamAdvisorForProposal = Pick<
  AccountUser,
  "email" | "firstName" | "fullName" | "username" | "calComUserId"
>;
