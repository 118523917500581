/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { Recommendation } from "./Recommendation";
import { Button } from "@a_team/ui-components";
import { FlexCenter } from "views/Builder/components/Flex";
import { TruncatedContent } from "views/Builder/components/TruncatedContent";
import { UserReviewObject } from "../../../../models/UserReviews";
import { LinkedInRecommendationObject } from "models/LinkedInRecommendations";
import { LinkedInRecommendation } from "views/Mission/Proposals/Native/LinkedInRecommendation";

interface Props {
  reviews?: UserReviewObject[];
  linkedInRecommendations?: LinkedInRecommendationObject[];
}

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    display: "flex",
    gap: 32,
    flexDirection: "column",
  },
});

const RECOMMENDATIONS_TO_SHOW_BY_DEFAULT = 4;

export const Recommendations = (props: Props) => {
  const { reviews = [], linkedInRecommendations = [] } = props;
  const styles = useCSSRulesWithTheme(getCSSRules);

  const allRecommendations = [
    ...reviews.map((review) => ({ source: "ateam" as const, data: review })),
    ...linkedInRecommendations.map((rec) => ({
      source: "linkedin" as const,
      data: rec,
    })),
  ];

  const truncatedContent = (
    <div css={styles.container}>
      {allRecommendations
        .slice(0, RECOMMENDATIONS_TO_SHOW_BY_DEFAULT)
        .map((item) =>
          item.source === "ateam" ? (
            <Recommendation key={item.data.id} review={item.data} />
          ) : (
            <LinkedInRecommendation
              key={item.data.id}
              linkedInRecommendation={item.data}
            />
          )
        )}
    </div>
  );

  const shouldShowButton =
    allRecommendations.length > RECOMMENDATIONS_TO_SHOW_BY_DEFAULT;

  if (allRecommendations.length === 0) {
    return null;
  }

  return (
    <TruncatedContent
      expandComponent={
        shouldShowButton
          ? (expand) => (
              <FlexCenter style={{ paddingTop: 24 }}>
                <Button onClick={() => expand()} size="sm" variant="secondary">
                  See more recommendations
                </Button>
              </FlexCenter>
            )
          : undefined
      }
      truncateComponent={
        shouldShowButton
          ? (truncate) => (
              <FlexCenter style={{ paddingTop: 24 }}>
                <Button
                  onClick={() => truncate()}
                  size="sm"
                  variant="secondary"
                >
                  Collapse
                </Button>
              </FlexCenter>
            )
          : undefined
      }
      truncatedContent={truncatedContent}
    >
      <div css={styles.container}>
        {allRecommendations.map((item) =>
          item.source === "ateam" ? (
            <Recommendation key={item.data.id} review={item.data} />
          ) : (
            <LinkedInRecommendation
              key={item.data.id}
              linkedInRecommendation={item.data}
            />
          )
        )}
      </div>
    </TruncatedContent>
  );
};
