/** @jsxImportSource @emotion/react */

import { FC, useMemo, useState } from "react";

import {
  Container,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  Row,
  Typography,
} from "@a_team/ui-components";
import { logger } from "@sentry/utils";
import { ClientSettingsWorkspaceLocation } from "Locations";
import ErrorMessage from "components/ErrorMessage";
import TrackButton from "components/TrackButton";
import TrackModal from "components/TrackModal";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { AdminAccount } from "models/Account";
import { ApiError } from "models/ApiError";
import { useDeleteAccount } from "queries/accounts/useDeleteAccount";
import { useHistory } from "react-router-dom";
import usePortal from "react-useportal";
import useSelectAccount from "views/Admin/AdminWorkspaces/useSelectAccount";
import WorkspaceAdvisorModal from "views/Admin/WorkspaceAdvisorModal";
import { canDeleteMissionSpec } from "./helpers";
import getCSSRules from "./styles";

export interface AccountRowMenuProps {
  account: AdminAccount;
}

const DeleteConfirmationModal: FC<{
  onConfirm(): void;
  onClose(): void;
  title: string;
  error: null | ApiError;
}> = ({ title, error, onClose, onConfirm }) => {
  const { Portal } = usePortal({
    bindTo: document.getElementById("root") as HTMLElement,
  });

  return (
    <Portal>
      <TrackModal
        variant="action"
        isOpen={true}
        onClose={onClose}
        name="Confirm admin delete spec"
      >
        <Container>
          <Row>
            <Typography
              variant="textLarge"
              component="h3"
              weight="bold"
              size={18}
            >
              Are you sure you want to remove {title} workspace?
            </Typography>
          </Row>
          <Row>
            <Typography variant="textMedium" component="h3">
              All information about this workspace and its missions will be
              deleted. This cannot be undone.
            </Typography>
          </Row>
          <Row justify="end" style={{ marginBottom: 0 }}>
            <>
              <ErrorMessage
                css={{ marginRight: "auto" }}
                error={error ?? undefined}
              />
            </>
            <>
              {
                <TrackButton
                  name="Cancel removing spec"
                  style={{ marginRight: 12 }}
                  onClick={onClose}
                  variant="secondary"
                  size="md"
                >
                  <Typography variant="textMedium">Cancel</Typography>
                </TrackButton>
              }
              {
                <TrackButton
                  name="Confirm removing spec"
                  variant={"negative"}
                  onClick={onConfirm}
                  size="md"
                  data-testing-id={"confirmation-modal-confirm"}
                >
                  <Typography variant="textMedium">Remove</Typography>
                </TrackButton>
              }
            </>
          </Row>
        </Container>
      </TrackModal>
    </Portal>
  );
};

const AccountRowMenu: FC<AccountRowMenuProps> = ({ account }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const history = useHistory();
  const { Portal } = usePortal();
  const [advisorModalOpen, setAdvisorModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const {
    id,
    workspace: { name },
  } = account;
  const selectAccount = useSelectAccount();
  const { mutateAsync: deleteAccount, error: deleteError } = useDeleteAccount({
    accountId: id,
  });

  const canDelete = useMemo(() => {
    const missionSpecs = account.missionSpecs.filter(Boolean);
    return !missionSpecs.length || missionSpecs.every(canDeleteMissionSpec);
  }, [account]);

  const confirmDelete = async () => {
    try {
      await deleteAccount();
      setDeleteModalOpen(false);
    } catch (error) {
      logger.error(error);
    }
  };

  const handleRemoveAccount = async () => {
    setDeleteModalOpen(true);
  };

  const handleGoToSetting = () => {
    selectAccount(id).then(() => {
      history.push(ClientSettingsWorkspaceLocation);
    });
  };

  return (
    // prevent row click from triggering
    <div onClick={(e) => e.stopPropagation()} css={styles.rowMenu}>
      <DropdownMenu triggerComponent={<Icon name="more" />}>
        <DropdownMenuItem onClick={handleGoToSetting}>
          Settings
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setAdvisorModalOpen(true)}>
          Change team advisor
        </DropdownMenuItem>
        {canDelete && (
          <DropdownMenuItem danger={true} onClick={handleRemoveAccount}>
            Remove workspace
          </DropdownMenuItem>
        )}
      </DropdownMenu>
      {deleteModalOpen && (
        <DeleteConfirmationModal
          error={deleteError as ApiError}
          title={name}
          onConfirm={confirmDelete}
          onClose={() => {
            setDeleteModalOpen(false);
          }}
        />
      )}
      <Portal>
        {advisorModalOpen && (
          <WorkspaceAdvisorModal
            isOpen={true}
            accountId={id}
            onClose={() => setAdvisorModalOpen(false)}
          />
        )}
      </Portal>
    </div>
  );
};

export default AccountRowMenu;
