import {
  ALLOWED_MINIMUM_COMMITMENT,
  AVG_HOURS_PER_MONTH,
  AVG_WEEKS_PER_MONTH,
  MAX_ROLE_DESCRIPTION_LENGTH,
  MIN_ROLE_DESCRIPTION_LENGTH,
  MAX_CUSTOM_QUESTION_LENGTH,
} from "views/Mission/TeamSpecV2/constants";
import { RoleV3, RoleError, RateInterval } from "../types";
import { isString, capitalize } from "lodash";
import { validateMarkup } from "components/Roles/Role/Edit/RoleMarkup";
import { RegisteredUserObject } from "@a_team/models/dist/UserObject";
import { MissionRoleStatus } from "@a_team/models/dist/MissionRole";
import { ApprovalStatus, MissionSpecStatus } from "models/MissionSpec";
import { TalentSkillExtended } from "services/talentSkills";
import RoleCategory from "models/RoleCategory";

export const mapRoleStatusToHumanReadable = (
  status?: MissionRoleStatus,
  specStatus?: MissionSpecStatus,
  approvalStatus?: ApprovalStatus
) => {
  if (approvalStatus === ApprovalStatus.Requested) {
    return "Awaiting approval";
  }
  if (approvalStatus === ApprovalStatus.Denied) {
    return "Approval denied";
  }
  if (approvalStatus === ApprovalStatus.Approved) {
    return "Approval - needs support help";
  }
  if (status === "Active") {
    return "Active role";
  }
  if (status === "ScheduledToEnd") {
    return "Scheduled to end";
  }
  if (status === "Ended") {
    return "Ended role";
  }
  if (status === "Canceled") {
    return "Canceled role";
  }
  if (status === "Open" || (!status && specStatus === "published")) {
    return "Currently hiring";
  }
  if (status === "Open" || (!status && specStatus === "formation")) {
    return "Hiring role";
  }
  if (specStatus === "published" && !status && !approvalStatus) {
    return "Not saved";
  }

  return "Ready to publish";
};

export const simpleHumanizeStatus = (status?: MissionRoleStatus) => {
  if (status === MissionRoleStatus.ScheduledToEnd) {
    return "Scheduled to end";
  }
  if (status) {
    return status;
  }

  return "Inactive";
};

export const checkRoleCompleteness = (
  role?: RoleV3 | null,
  user?: RegisteredUserObject,
  forUpdate?: boolean // enforce only the required fields for role update
): null | {
  errors: RoleError[];
  preventAutoSave: boolean;
  isComplete: boolean;
} => {
  if (!role) {
    return null;
  }

  const {
    description = "",
    category,
    minimumCommitment = NaN,
    requiredSkills = [],
    customQuestions = [],
  } = role;
  const descriptionLength = (description ?? "").trim().length ?? 0;
  const errors: RoleError[] = [];

  if (!isString(category)) {
    errors.push({
      tag: "category",
      message: "Category is missing",
    });
  }

  if (!forUpdate && !ALLOWED_MINIMUM_COMMITMENT.includes(minimumCommitment)) {
    errors.push({
      tag: "minimumCommitment",
      message: "Time commitment is missing",
    });
  }

  // A budget type must be selected
  if (!forUpdate && typeof role.budgetType === "undefined") {
    errors.push({
      tag: "budgetType",
      message: "Budget type is missing",
    });
  }

  // Budget amount must be set
  if (!forUpdate && typeof role.budget === "undefined") {
    errors.push({
      tag: "budget",
      message: "Budget is missing",
    });
  }

  // Validate budget amount if there is one
  if (!forUpdate && typeof role.budget !== "undefined") {
    const hourlyRate =
      calculateHourlyRate(
        role.budget ?? 0,
        role.minimumCommitment ?? 40,
        role.budgetType ?? "hourly"
      ) ?? 0;

    if (hourlyRate < 20) {
      errors.push({
        tag: "budget",
        message: `Budget too low for ${role.budgetType ?? "hourly"} rate`,
      });
    }
  }

  if (!forUpdate && descriptionLength < MIN_ROLE_DESCRIPTION_LENGTH) {
    errors.push({
      tag: "description",
      message: `Role description is ${
        !descriptionLength ? "missing" : "too short"
      }`,
    });
  }

  if (descriptionLength >= MAX_ROLE_DESCRIPTION_LENGTH) {
    errors.push({
      tag: "description",
      message: `Role description is too long. Max is ${MAX_ROLE_DESCRIPTION_LENGTH} characters.`,
      preventAutoSave: true,
    });
  }

  if (!forUpdate && requiredSkills.length === 0) {
    errors.push({
      tag: "requiredSkills",
      message: 'Select at least 1 "required skill"',
    });
  }

  if (!forUpdate && user?.isAdmin) {
    const { isValid, errorMessage } = validateMarkup(
      role._PRIVATE_ROLE_MARGIN,
      true
    );
    if (!isValid) {
      errors.push({
        tag: "_PRIVATE_ROLE_MARGIN",
        message: errorMessage || "Invalid role markup",
      });
    }
  }

  if (
    !forUpdate &&
    customQuestions[0]?.text.length > MAX_CUSTOM_QUESTION_LENGTH
  ) {
    errors.push({
      tag: "customQuestions",
      message: "Screening question is too long",
      preventAutoSave: true,
    });
  }

  return {
    errors,
    isComplete: errors.length === 0,
    preventAutoSave: errors.some((e) => e.preventAutoSave),
  };
};

export const describeCommitment = (commitment: number = 0) => {
  if (commitment >= 40) {
    return "Full-time hours, 40+";
  }

  switch (commitment) {
    case 5:
      return "Flexible hours, 5+";
    case 10:
      return "Flexible hours, 10+";
    case 20:
      return "Flexible hours, 20+";
    case 30:
      return "Flexible hours, 30+";
    case commitment && commitment > 0:
      return `${commitment} hours/week`;
    default:
      return undefined;
  }
};
export function formatToUSD(amount: number): string {
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  // Remove the cents if it's .00
  return formatted.endsWith(".00") ? formatted.slice(0, -3) : formatted;
}

export const describeBudget = (role: RoleV3) => {
  const { budget, budgetType = "hourly", minimumCommitment = 40 } = role;

  if (!budget) {
    return "Budget not specified";
  }

  let monthlyTotalExtension: string | undefined = undefined;
  if (budgetType === "hourly") {
    const monthlyTotal = budget * minimumCommitment * AVG_WEEKS_PER_MONTH;
    monthlyTotalExtension = ` (${formatToUSD(monthlyTotal)}/mo)`;
  }

  return `${capitalize(budgetType)} role budget ${formatToUSD(budget)}${
    monthlyTotalExtension ? monthlyTotalExtension : ""
  }`;
};

export const determineRoleKey = (
  role?: RoleV3
): "roles" | "roles" | "pendingRoles" | "missionRoles" => {
  if (role?.approvalStatus) {
    return "pendingRoles";
  }
  if (role?.status) {
    return "missionRoles";
  }
  return "roles";
};

export const calculateSliderPercentage = (
  minRate: number,
  maxRate: number,
  currentRate: number,
  minPercentage = 0
): number => {
  if (minRate >= maxRate) {
    return minPercentage;
  }
  const range = maxRate - minRate;
  const currentRange = currentRate - minRate;
  const percentage = (currentRange / range) * 100;
  return Math.min(Math.max(percentage, minPercentage), 100 - minPercentage);
};

export const mapRoleNameToCategoryId = (
  roleName: string,
  roleCategories?: RoleCategory[]
) => {
  const roleCategory = roleCategories?.find(
    (r) => r.title.toLowerCase() === roleName.toLowerCase()
  );
  return roleCategory?.cid;
};

export const mapSkillNameToSkillId = (
  skillName: string,
  skills?: TalentSkillExtended[]
) => {
  const skill = skills?.find(
    (s) => s.name.toLowerCase() === skillName.toLowerCase()
  );
  return skill?.id;
};

export const mapSkillIdToSkillName = (
  skillId: string,
  skills?: TalentSkillExtended[]
) => {
  const skill = skills?.find((s) => s.id === skillId);
  return skill?.name;
};

// Takes data from the server and maps it to the client budget
export const mapServerRateToBudget = (
  budget?: number, // As clientRateMax, which is always hourly
  timeCommittment?: number,
  budgetType?: RateInterval
) => {
  if (!budget) {
    return undefined;
  }

  if (budgetType === "monthly") {
    const hoursPerMonth = timeCommittment
      ? timeCommittment * AVG_WEEKS_PER_MONTH
      : AVG_HOURS_PER_MONTH;
    return budget * hoursPerMonth;
  }

  return budget;
};

// Used on client side to calculate the hourly rate
// based on the budget and time commitment
export const calculateHourlyRate = (
  budget?: number, // represented as an amount that's either hourly or monthly
  timeCommittment?: number,
  budgetType?: RateInterval
) => {
  if (!budget) {
    return undefined;
  }

  if (budgetType === "monthly") {
    const hoursPerMonth = timeCommittment
      ? timeCommittment * AVG_WEEKS_PER_MONTH
      : AVG_HOURS_PER_MONTH;
    return budget / hoursPerMonth;
  }

  return budget;
};

// Used to calculate the new hourly rate when switching between budget types
export const calculateHourlyRateFromSwitch = (
  currentState: {
    budget?: number;
    budgetType?: RateInterval;
    minimumCommitment?: number;
  },
  prevState?: {
    budget?: number;
    budgetType?: RateInterval;
    minimumCommitment?: number;
  }
) => {
  if (typeof currentState.budget === "undefined") {
    return undefined;
  }

  const currentHoursPerWeek = currentState.minimumCommitment ?? 40;
  const currentHoursPerMonth = currentHoursPerWeek * AVG_WEEKS_PER_MONTH;

  const prevHoursPerWeek = prevState?.minimumCommitment ?? 40;
  const prevHoursPerMonth = prevHoursPerWeek * AVG_WEEKS_PER_MONTH;

  // Handle budget type switch from 'hourly' to 'monthly'
  if (
    currentState.budgetType === "monthly" &&
    prevState?.budgetType === "hourly"
  ) {
    return currentState.budget * currentHoursPerMonth;
  }

  // Handle budget type switch from 'monthly' to 'hourly'
  if (
    currentState.budgetType === "hourly" &&
    prevState?.budgetType === "monthly"
  ) {
    return currentState.budget / currentHoursPerMonth;
  }

  // Handle changes in minimumCommitment for 'monthly' budget type
  if (
    currentState.budgetType === "monthly" &&
    currentState.minimumCommitment !== prevState?.minimumCommitment
  ) {
    const hourlyRate = currentState.budget / prevHoursPerMonth;
    return hourlyRate * currentHoursPerMonth;
  }

  // Handle changes in minimumCommitment for 'hourly' budget type
  if (
    currentState.budgetType === "hourly" &&
    currentState.minimumCommitment !== prevState?.minimumCommitment
  ) {
    return currentState.budget;
  }

  // Default case: Return the current budget
  return currentState.budget;
};

// A budget assumes a max rate and no min rate
export const mapStateBudgetToServerClientRate = (
  budget: RoleV3["budget"] = 0,
  budgetType: RoleV3["budgetType"] = "hourly",
  minimumCommitment?: number
): { clientRateMin?: number; clientRateMax?: number } => {
  if (budgetType === "hourly") {
    return {
      clientRateMin: undefined,
      clientRateMax: budget,
    };
  }

  const hoursPerWeek = minimumCommitment ?? 40;
  const hoursPerMonth = hoursPerWeek * AVG_WEEKS_PER_MONTH;

  return {
    clientRateMin: undefined,
    clientRateMax: budget / hoursPerMonth,
  };
};
