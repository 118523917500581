/** @jsxImportSource @emotion/react */

import { HomeLocation } from "Locations";
import { CustomRoute, CustomRouteProps, routes } from "Routes";
import LoadingFallback from "components/LoadingFallback";
import {
  WORKSPACE_BANNER_HEIGHT,
  WORKSPACE_BODY_CLASS,
} from "components/WorkspaceBanner";
import { fadeIn, fadeOut, slideLeft, slideRight } from "helpers/animations";
import { isRouteAllowed } from "helpers/routes";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import useDrawerClose from "hooks/useDrawerAnimateClose";
import { observer } from "mobx-react";
import { useFeatureFlags } from "queries/featureFlags/useFeatureFlags";
import { FC, FunctionComponent, Suspense, useMemo } from "react";
import { RouteProps, Switch, useHistory, useRouteMatch } from "react-router";
import { useRootStore } from "store";

interface DrawerRouteProps extends CustomRouteProps {
  parentLocation?: RouteProps["path"];
}

const cssRules: CSSRulesResolver<{
  drawerAnimSeconds: number;
  minAnimationWidth: number;
  isOpen: boolean;
}> = ({ isOpen, drawerAnimSeconds, minAnimationWidth }) => ({
  drawer: {
    position: "fixed",
    height: "100%",
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 10002,
    [`body.${WORKSPACE_BODY_CLASS} &`]: {
      top: WORKSPACE_BANNER_HEIGHT,
      height: `calc(100% - ${WORKSPACE_BANNER_HEIGHT}px)`,
    },
  },
  overlay: {
    cursor: "pointer",
    position: "fixed",
    zIndex: 10000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0, .25)",

    [`@media (min-width: ${minAnimationWidth}px)`]: {
      '[data-drawer-open="false"] &': {
        animation: `${fadeOut} ${drawerAnimSeconds}s ease`,
      },
      '[data-drawer-open="true"] &': {
        animation: `${fadeIn} ${drawerAnimSeconds}s ease`,
      },
    },
  },
  drawerInner: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "0px 20px 24px",
    boxSizing: "border-box",
    zIndex: 10003,
    width: "100vw",
    position: "relative",
    backgroundColor: "#F9FAFC",

    [`@media (min-width: ${minAnimationWidth}px)`]: {
      width: isOpen ? 760 : 440,
      '[data-drawer-open="false"] &': {
        animation: `${slideRight} 0.25s ease`,
      },
      '[data-drawer-open="true"] &': {
        animation: `${slideLeft} 0.25s ease`,
      },
    },
  },
});

const DrawerRoute: FC<DrawerRouteProps> = observer((props) => {
  const { uiStore } = useRootStore();
  const history = useHistory();
  const { parentLocation } = props;
  const match = useRouteMatch(String(parentLocation || ""));
  const back = match?.url ?? undefined;

  const {
    close: handleGoBack,
    durationSeconds,
    minAnimationWidth,
  } = useDrawerClose(() => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(back || HomeLocation, { back: true });
    }
  });

  const styles = useCSSRulesWithTheme(cssRules, {
    isOpen: uiStore.isDrawerOpen,
    drawerAnimSeconds: durationSeconds,
    minAnimationWidth,
  });

  return (
    <>
      <div css={styles.overlay} onClick={handleGoBack}></div>
      <div css={styles.drawerInner}>
        <Suspense fallback={<LoadingFallback height="100%" />}>
          <CustomRoute {...props} />
        </Suspense>
      </div>
    </>
  );
});

const Drawer: FunctionComponent = () => {
  const styles = useCSSRulesWithTheme(cssRules);
  const {
    uiStore: { isDrawerOpen },
  } = useRootStore();

  const { data = [], isLoading } = useFeatureFlags();

  const drawerRoutes = useMemo(() => {
    return routes.reduce<DrawerRouteProps[]>((acc, route) => {
      if (!route.drawerRoutes) {
        return acc;
      }

      return acc.concat(
        ...route.drawerRoutes
          .filter((r) => isRouteAllowed(r, isLoading, data))
          .map((r) => ({
            ...r,
            parentLocation: route.path || undefined,
            location: undefined,
          }))
      );
    }, []);
  }, []);

  return (
    <div css={styles.drawer} data-drawer-open={Boolean(isDrawerOpen)}>
      <Switch>
        {drawerRoutes.map((route, index) => (
          <DrawerRoute
            key={index}
            parentLocation={route.parentLocation || undefined}
            component={route.component}
            {...route}
          />
        ))}
      </Switch>
    </div>
  );
};

export default observer(Drawer);
