import { useQuery } from "@tanstack/react-query";
import { getConsolidatedInterviewBookings } from "services/interview";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useInterviewBookings = (proposalId: string) => {
  const { authStore } = useRootStore();

  return useQuery(
    queryKeys.interview.list({ proposalId }).queryKey,
    () => getConsolidatedInterviewBookings(authStore, proposalId),
    {
      enabled: authStore.isLoggedIn && Boolean(proposalId),
    }
  );
};
