/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
import {
  Avatar,
  Icon,
  Input,
  Textarea,
  Typography,
} from "@a_team/ui-components";
import { BookInterviewUser } from "components/BookInterviewButton";
import TrackButton from "components/TrackButton";
import { useFormik } from "formik";
import useBuilderAvatar from "hooks/useBuilderAvatar";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useIsMobile } from "hooks/useIsMobile";
import { TargeterUserCard } from "models/User";
import { FC, useMemo } from "react";
import { Flex } from "views/Builder/components/Flex";
import * as Yup from "yup";
import { useIsEnterprise } from "queries/discover/useIsEnterprise";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { Flags } from "configs/featureFlags";

export interface IntroQuestionsFormData {
  minRate: string;
  maxRate: string;
  description: string;
}

export interface BookDirectInterviewModalProps {
  user: BookInterviewUser;
  onNext: (data: IntroQuestionsFormData) => void;
  minBuilderRate?: number;
}

const BUILDER_MIN_AVERAGE_RATE = 40;
const BUILDER_MAX_AVERAGE_RATE = 75;

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    gap: 24,
    margin: "-16px -4px 0",
    "& section": {
      width: "100%",
    },

    [`@media (min-width: ${breakpoints.sm}px)`]: {
      margin: "16px auto 0",
      maxWidth: 600,
    },
  },
  card: {
    backgroundColor: colors.Grey[100],
    borderRadius: 8,
    padding: 16,
    color: colors.Grey[900],
    border: `1px solid ${colors.Grey[200]}`,
    width: "100%",
    boxSizing: "border-box",
  },
  avatar: {
    flex: "0 0 48px",
  },
  builderData: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: 12,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginTop: 0,
    },
  },
  builderDataItem: {
    padding: 0,
    "&:not(:last-of-type)::after": {
      content: "''",
      margin: "0 8px",
      display: "inline-block",
      width: 1,
      height: 12,
      background: colors.Grey[300],
    },
    whiteSpace: "nowrap",
  },
  inputsTitle: {
    marginBottom: 2,
  },
  textarea: {
    minHeight: 100,
    marginTop: 6,
  },
  inputIcon: {
    marginTop: -2,
  },
  hourlyRateInputs: {
    marginTop: 6,
    fontSize: 14,
    "& input": {
      paddingLeft: 24,
      maxWidth: 120,
      "-moz-appearance": "textfield",
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  bottomSection: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: 12,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      flexDirection: "row",
      gap: 8,
    },
  },
  alert: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    backgroundColor: colors.Hannibal[100],
    color: colors.Grey[900],
    fontSize: 12,
    padding: 12,
    borderRadius: 4,
    flex: "1",
  },
  nextButton: {
    padding: "10px 40px",
    borderRadius: 4,
  },
  inputsError: {
    display: "block",
    marginTop: 12,
  },
});

const DESCRIPTION_MIN_LENGTH = 40;

export const IntroQuestionsForm: FC<BookDirectInterviewModalProps> = ({
  user,
  onNext,
  minBuilderRate = BUILDER_MIN_AVERAGE_RATE,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const isMobile = useIsMobile();
  const isEnterprise = useIsEnterprise();

  const { flagEnabled: directInterviewRatesFlag } = useFeatureFlag(
    Flags.DirectInterviewBookingHourlyRates
  );
  const hourlyRatesEnabled = isEnterprise === false && directInterviewRatesFlag;

  const inputIcon = <div css={styles.inputIcon}>$</div>;

  const validationSchema = Yup.object({
    description: Yup.string().required().min(DESCRIPTION_MIN_LENGTH),
    minRate: Yup.number().when("hourlyRatesEnabled", {
      is: true,
      then: Yup.number()
        .required("This field is required")
        .min(minBuilderRate ?? 0, `Minimum rate is ${minBuilderRate}`),
    }),
    maxRate: Yup.number().when("hourlyRatesEnabled", {
      is: true,
      then: Yup.number()
        .required()
        .max(1000)
        .when(["minRate"], (minRate, schema) => schema.min(minRate)),
    }),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      minRate: "",
      maxRate: "",
      description: "",
      hourlyRatesEnabled,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (formValues) => {
      onNext(formValues);
    },
  });

  const userData = useMemo(() => {
    if ((user as UserObject).yearsOfExperience) {
      const data = user as UserObject;
      return {
        ...user,
        title:
          data.talentProfile?.talentSpecializations?.mainTalentSpecialization
            ?.name,
        location: data.location,
        yearsOfExperience: data.yearsOfExperience,
        availability: data.availability,
        email: data.email,
      };
    } else {
      const data = user as TargeterUserCard;
      return {
        ...user,
        title: data.role?.title,
        location: data.location,
        yearsOfExperience: data.yearsExperience,
        availability: data.availability?.availability,
        email: "",
      };
    }
  }, [user]);

  const builderData = (
    <div css={styles.builderData}>
      {!isMobile && (
        <Typography variant="textMedium" css={styles.builderDataItem}>
          {userData.title}
        </Typography>
      )}
      <Typography variant="textMedium" css={styles.builderDataItem}>
        {userData.location?.city},{" "}
        {userData.location?.countryShortName ?? userData.location?.country}
      </Typography>
      <Typography variant="textMedium" css={styles.builderDataItem}>
        {userData.yearsOfExperience} years exp
      </Typography>
      <Typography variant="textMedium" css={styles.builderDataItem}>
        {userData.availability?.weeklyHoursAvailable} hours/week
      </Typography>
    </div>
  );

  const avatarUrl = useBuilderAvatar(user.profilePictureURL || "");

  const inputsError = useMemo(() => {
    const values = formik.values;
    if (!values.minRate || !values.maxRate) {
      return null;
    }

    const minRate = Number(values.minRate);
    const maxRate = Number(values.maxRate);
    let errorMessage = "";
    if (minRate > maxRate) {
      errorMessage =
        "The maximum hourly rate should to be higher than the minimum hourly rate.";
    } else if (minRate < minBuilderRate || maxRate < BUILDER_MAX_AVERAGE_RATE) {
      errorMessage = "This range is below average.";
    }

    if (errorMessage) {
      return (
        <Typography
          variant="textSmall"
          color="Red@600"
          css={styles.inputsError}
        >
          {errorMessage}
        </Typography>
      );
    } else {
      return null;
    }
  }, [formik.values.minRate, formik.values.maxRate]);

  return (
    <form css={styles.content} onSubmit={formik.handleSubmit}>
      <div css={styles.card}>
        <Flex gap={16} alignItems="center" wrap="nowrap">
          <Avatar
            size="lg"
            src={avatarUrl}
            css={styles.avatar}
            style={{ width: 48, height: 48 }}
          />
          <div>
            <Typography variant="textLarge" component="div">
              {userData.fullName}
            </Typography>
            {isMobile ? (
              <Typography variant="textMedium">{userData.title}</Typography>
            ) : (
              builderData
            )}
          </div>
        </Flex>
        {isMobile && builderData}
      </div>
      <section>
        <Typography
          variant="textLarge"
          component="div"
          css={styles.inputsTitle}
        >
          Describe the problem you want to solve
        </Typography>
        <Typography variant="textMedium" component="div">
          Be descriptive and include as many details as possible.
        </Typography>
        <Textarea
          placeholder={`I am looking for a highly-qualified ${
            userData.title?.toLowerCase() || "builder"
          } who will ...`}
          description={`Enter at least ${DESCRIPTION_MIN_LENGTH} characters.`}
          size="stretch"
          css={styles.textarea}
          name="description"
          onChange={formik.handleChange}
        />
      </section>

      {hourlyRatesEnabled && (
        <section>
          <Typography
            variant="textLarge"
            component="div"
            css={styles.inputsTitle}
          >
            Include your hourly rate
          </Typography>
          <Typography variant="textMedium" component="div">
            For visibility, share your hourly rate range for this role.
          </Typography>
          <Flex wrap="nowrap" gap={8} css={styles.hourlyRateInputs}>
            From
            <Input
              placeholder="0"
              type="number"
              name="minRate"
              icon={inputIcon}
              onChange={formik.handleChange}
            />
            to
            <Input
              placeholder="0"
              type="number"
              name="maxRate"
              icon={inputIcon}
              onChange={formik.handleChange}
            />
          </Flex>
          {inputsError}
        </section>
      )}

      <section css={styles.bottomSection}>
        <div css={styles.alert}>
          <Icon name="details" color="Hannibal@600" size="md" />
          <span>
            They will receive your request and have 24 hours to respond.
          </span>
        </div>
        <TrackButton
          name="Direct interview booking - step 1"
          disabled={!formik.isValid}
          css={styles.nextButton}
        >
          Next
        </TrackButton>
      </section>
    </form>
  );
};
