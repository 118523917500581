import { loadMissionStore } from "loaders";
import { MissionLocation } from "Locations";
import { lazy } from "react";
import { CustomRouteProps } from "Routes";

const Mission = lazy(() => import("views/Mission"));

export const MissionRoutes: CustomRouteProps[] = [
  {
    withAuth: true,
    path: MissionLocation,
    component: Mission,
    dataLoader: loadMissionStore,
    title: "Team Spec",
    requireAccount: true,
  },
];
