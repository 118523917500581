import { useQuery } from "@tanstack/react-query";
import { useRootStore } from "store";
import queryKeys from "queries/keys";
import { getMissionSpecV3 } from "services/missionSpec.v3";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";

export const useFetchSpec = (id?: string) => {
  const {
    authStore,
    missionSpecStore,
    accountsStore: { currentAccountId },
  } = useRootStore();

  const specificity = useQueryKeySpecificity({
    missionSpecId: id,
    v3: true,
  });

  return useQuery({
    queryKey: queryKeys.missionSpecs.byId(specificity).queryKey,
    queryFn: () => getMissionSpecV3(authStore, id as string),
    enabled:
      !!authStore.isLoggedIn && !!id && !!currentAccountId && id !== "new",
    onSuccess: () => {
      // Let the mobx store know we've fetched a mission spec
      missionSpecStore.invalidateMissionCache();
    },
    onError: (error) => {
      console.error(`Failed to fetch mission spec ${id}`, error);
    },
    retry: (failureCount, error) => {
      if ((error as unknown as { status?: number })?.status === 404) {
        return false;
      }

      return failureCount < 3;
    },
  });
};
