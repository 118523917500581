import { Tooltip } from "@a_team/ui-components";
import useCheckAccessModal from "components/AccessModalProvider/useCheckAccessModal";
import { BOOK_INTERVIEW_MISSION_READ_ACCESS_REQUIRED } from "components/Proposals/ReviewBuilders/constants";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import TrackButton from "components/TrackButton";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import useIsUsersBookingEnabled from "hooks/useIsUsersBookingEnabled";
import { useRootStore } from "store";
import NoAccessModalContent from "views/Mission/Proposals/Native/NoAccessModalContent";

export const InterviewButton = ({
  builderId,
  onClick,
  selected,
}: {
  builderId: string;
  onClick: () => void;
  selected: boolean;
}) => {
  const {
    authStore: { isLoggedIn },
  } = useRootStore();

  const { checkAndHandlePermission } = useCheckAccessModal();
  const { currentProposal, canBookInterviews } = useProposalContext();

  const isSmallScreen = useBelowBreakpoint("sm");
  const isBookingEnabled = useIsUsersBookingEnabled(builderId);

  const buttonName = "Book an interview";

  if (!isLoggedIn) {
    return null;
  }

  let tooltip = "";
  if (!canBookInterviews) {
    tooltip = BOOK_INTERVIEW_MISSION_READ_ACCESS_REQUIRED;
  } else if (!isBookingEnabled) {
    tooltip =
      "This builder cannot be booked because they have not shared their calendar link.";
  }

  const isDisabled = !isBookingEnabled || !canBookInterviews;

  const onClickWrapper = async () => {
    const hasPermission = await checkAndHandlePermission(
      {
        title: "Request access",
        content: <NoAccessModalContent />,
        forMissionSpecId: currentProposal?.missionSpecId,
      },
      () => canBookInterviews
    );

    if (hasPermission) {
      onClick?.();
    }
  };

  return (
    <Tooltip text={tooltip} disabled={!tooltip}>
      <span>
        <TrackButton
          name={buttonName}
          variant="main"
          size={isSmallScreen ? "md" : "sm"}
          onClick={onClickWrapper}
          width="auto"
          iconProps={selected ? { name: "statusPositiveNoBorder" } : undefined}
          style={{ width: "100%" }}
          disabled={isDisabled}
        >
          {buttonName}
        </TrackButton>
      </span>
    </Tooltip>
  );
};
