/** @jsxImportSource @emotion/react */

import { LinkedInRecommendationObject } from "models/LinkedInRecommendations";
import { Typography, Avatar } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { format } from "date-fns";

interface Props {
  linkedInRecommendation: {
    source?: "linkedin";
  } & LinkedInRecommendationObject;
  headingFontWeight?: "extra-bold" | "regular" | "bold";
}

export const getCSSRulesForRecommendation: CSSRulesResolver = ({ colors }) => ({
  container: {
    display: "flex",
    gap: 24,
  },
  logoColumn: {
    flexShrink: 0,
  },
  contentColumn: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  titleContainer: {
    display: "flex",
    gap: 4,
    alignItems: "center",
  },
  details: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 8,
    rowGap: 4,
    alignItems: "center",
    width: "100%",
  },
  positionInfo: {
    display: "flex",
    alignItems: "center",
  },
  publishedInfo: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  divider: {
    width: 1,
    height: 12,
    backgroundColor: colors.Grey[300],
    margin: "0 4px",
  },
  icon: {
    width: 48,
    height: 48,
    backgroundColor: "#4CAF50",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  verticalLines: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    "& > div": {
      width: 3,
      height: 12,
      backgroundColor: "white",
      borderRadius: 1,
    },
  },
});

export const LinkedInRecommendation = (props: Props) => {
  const styles = useCSSRulesWithTheme(getCSSRulesForRecommendation);
  const { linkedInRecommendation, headingFontWeight = "bold" } = props;

  const recommenderName =
    linkedInRecommendation.recommender.firstName +
    (linkedInRecommendation.recommender?.lastName
      ? ` ${linkedInRecommendation.recommender?.lastName?.[0]}.`
      : "");

  return (
    <div css={styles.container}>
      <div css={styles.logoColumn}>
        <Avatar
          src={linkedInRecommendation.recommender.position?.companyLogo}
          size="md"
        />
      </div>
      <div css={styles.contentColumn}>
        <div css={styles.header}>
          <div css={styles.titleContainer}>
            <Typography variant="textLarge" weight="extra-bold">
              in
            </Typography>
            <Typography variant="textLarge" weight={headingFontWeight}>
              LinkedIn recommendation from {recommenderName}
            </Typography>
          </div>
          <div css={styles.details}>
            <div css={styles.positionInfo}>
              <Typography variant="textSmall" color="Grey@600">
                {linkedInRecommendation.recommender.position?.title} at{" "}
                {linkedInRecommendation.recommender.position?.companyName}
              </Typography>
            </div>
            <div css={styles.divider} />
            <div css={styles.publishedInfo}>
              <Typography variant="textSmall" color="Grey@600">
                Published on{" "}
                {format(
                  new Date(linkedInRecommendation.createdAt),
                  "MMMM d, yyyy"
                )}
              </Typography>
            </div>
          </div>
        </div>
        {linkedInRecommendation.text && (
          <Typography
            variant="textMedium"
            style={{
              whiteSpace: "pre-line",
            }}
          >
            {linkedInRecommendation.text}
          </Typography>
        )}
      </div>
    </div>
  );
};
