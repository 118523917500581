/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useGetVisibleUserReviews } from "queries/userReviews/userReviews";
import { Recommendation } from "views/Builder/components/Recommendation";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";
import { Button } from "@a_team/ui-components";
import { FlexCenter } from "views/Builder/components/Flex";
import { TruncatedContent } from "views/Builder/components/TruncatedContent";
import { RecommendationSkeleton } from "views/Builder/components/RecommendationSkeleton";
import { LinkedInRecommendation } from "views/Mission/Proposals/Native/LinkedInRecommendation";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { Flags } from "configs/featureFlags";

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    display: "flex",
    gap: 32,
    flexDirection: "column",
  },
});

const RECOMMENDATIONS_TO_SHOW_BY_DEFAULT = 3;

export const Recommendations = ({
  embedded = false,
  builderId,
}: {
  embedded?: boolean;
  builderId: string;
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { flagEnabled: linkedInRecommendationsEnabled } = useFeatureFlag(
    Flags.LinkedInRecommendations
  );

  const { data, isLoading } = useGetVisibleUserReviews({
    uid: builderId,
    includeLinkedInRecommendations: linkedInRecommendationsEnabled,
  });

  if (isLoading) {
    return (
      <Section embedded={embedded}>
        <SectionLabel iconName="star" embedded={embedded} isLoading={isLoading}>
          Recommendations
        </SectionLabel>
        <div css={styles.container}>
          {Array.from({ length: RECOMMENDATIONS_TO_SHOW_BY_DEFAULT }).map(
            (_, index) => (
              <RecommendationSkeleton key={index} />
            )
          )}
        </div>
      </Section>
    );
  }

  if (!data) {
    return null;
  }

  const truncatedContent = (
    <div css={styles.container}>
      {data.slice(0, RECOMMENDATIONS_TO_SHOW_BY_DEFAULT).map((review) => {
        if (review.source === "linkedin") {
          return (
            <LinkedInRecommendation
              key={review.id}
              linkedInRecommendation={review}
              headingFontWeight="regular"
            />
          );
        }

        return <Recommendation key={review.id} review={review} />;
      })}
    </div>
  );

  const shouldShowButton = data.length > RECOMMENDATIONS_TO_SHOW_BY_DEFAULT;

  if (data.length === 0) {
    return null;
  }

  return (
    <Section embedded={embedded}>
      <SectionLabel iconName="star" embedded={embedded} isLoading={isLoading}>
        Recommendations ({data.length})
      </SectionLabel>
      <TruncatedContent
        expandComponent={
          shouldShowButton
            ? (expand) => (
                <FlexCenter style={{ paddingTop: 24 }}>
                  <Button
                    onClick={() => expand()}
                    size="sm"
                    variant="secondary"
                  >
                    See more recommendations
                  </Button>
                </FlexCenter>
              )
            : undefined
        }
        truncateComponent={
          shouldShowButton
            ? (truncate) => (
                <FlexCenter style={{ paddingTop: 24 }}>
                  <Button
                    onClick={() => truncate()}
                    size="sm"
                    variant="secondary"
                  >
                    Collapse
                  </Button>
                </FlexCenter>
              )
            : undefined
        }
        truncatedContent={truncatedContent}
      >
        <div css={styles.container}>
          {data.map((review) => {
            if (review.source === "linkedin") {
              return (
                <LinkedInRecommendation
                  key={review.id}
                  linkedInRecommendation={review}
                  headingFontWeight="regular"
                />
              );
            }

            return <Recommendation key={review.id} review={review} />;
          })}
        </div>
      </TruncatedContent>
    </Section>
  );
};
