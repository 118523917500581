import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { useRootStore } from "store";

import queryKeys from "../keys";
import { getUserReviewsByUserId } from "services/userReviews";

export const useGetVisibleUserReviews = ({
  uid,
  includeLinkedInRecommendations = false,
}: {
  uid?: string;
  includeLinkedInRecommendations?: boolean;
}) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryKeySpecificity = useQueryKeySpecificity({ uid });

  const { queryKey } =
    queryKeys.userReviews.visibleUserReviewsByUserId(queryKeySpecificity);

  return useQuery({
    queryKey,
    queryFn: () =>
      getUserReviewsByUserId(uid as string, authStore, {
        includeLinkedInRecommendations,
      }),
    enabled: authStore.isLoggedIn && !!uid,
    onError: (error) => setApiErrorToast(error, "Failed to load reviews"),
  });
};
