import {
  BuilderChatLocation,
  BuilderLocation,
  CuratorLocation,
  DiscoverCategoryLocation,
  DiscoverLocation,
  DiscoverLocationV2,
  DiscoverSearchResultsLocation,
  EmailSentLocation,
  FeedbackLocation,
  HomeLocation,
  InterviewScheduleLocation,
  LegacyDiscoverLocation,
  OAuthLocation,
  ProposalLocation,
  PublicProposalLocation,
  RegisterLocation,
  ResetPasswordLocation,
  SetNewPasswordLocation,
  ShortlistLocation,
  SigninLocation,
  SignupLocation,
  TeamProposalLocation,
  ToSigninLocation,
  UseDesktopLocation,
  UserReviewLocation,
} from "Locations";
import AccountEnforcer from "components/AccountEnforcer";
import LoadingFallback from "components/LoadingFallback";
import config from "config";
import { Flags } from "configs/featureFlags";
import { Role } from "configs/role";
import getPageTitle from "helpers/title";
import { Location as HistoryLocation } from "history";
import useErrorHandler from "hooks/useErrorHandler";
import { DataLoader, loadMissionStore } from "loaders";
import { observer } from "mobx-react";
import { useSignOut } from "queries/auth/useSignOut";
import { useFeatureFlags } from "queries/featureFlags/useFeatureFlags";
import {
  Fragment,
  ReactElement,
  Suspense,
  lazy,
  useEffect,
  useMemo,
} from "react";
import { Helmet } from "react-helmet-async";
import {
  match as Match,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
} from "react-router-dom";
import { AdminRoutes } from "routes/admin";
import { ClientSettingsRoutes } from "routes/clientSettings";
import { MissionRoutes } from "routes/mission";
import { useRootStore } from "store";
import DiscoveryCategoryV2 from "views/Browse/DiscoverCategoryV2";
import SearchResults from "views/Browse/Search";
import { BuilderProfile } from "views/Builder";
import BuilderChat from "views/Chat/BuilderChat";
import PublicProposalView from "views/PublicProposal/PublicProposalView";
import UserReview from "./views/UserReview";
import { isRouteAllowed } from "helpers/routes";

const EmailSent = lazy(() => import("views/EmailSent"));
const Home = lazy(() => import("views/Home"));
const SingleProposalView = lazy(
  () => import("views/Mission/Proposals/SingleProposalView")
);
const ResetPassword = lazy(() => import("views/ResetPassword"));
const SetNewPassword = lazy(() => import("views/ResetPassword/SetNewPassword"));
const Signin = lazy(() => import("views/Signin"));
const Signup = lazy(() => import("views/Signup"));
const OAuth = lazy(() => import("views/OAuth"));
const UseDesktop = lazy(() => import("views/UseDesktop"));
const TeamProposal = lazy(() => import("./views/TeamProposal"));
const RegisterView = lazy(() => import("./views/Register"));
const LegacyDiscover = lazy(() => import("./views/Discover"));
const Discover = lazy(() => import("./views/Browse"));
const DiscoverV2 = lazy(() => import("./views/Browse/indexV2"));
const DiscoverCategory = lazy(() => import("./views/Browse/DiscoverCategory"));
const InterviewSchedule = lazy(() => import("./views/InterviewSchedule"));
const ShortlistView = lazy(() => import("./views/Shortlist"));
const Feedback = lazy(() => import("./views/Feedback"));
const CuratorChatRouter = lazy(() => import("./views/Chat/CuratorChatRouter"));

const IS_PROD = config.isProd;

export type LocationState = {
  [key: string]: string | undefined | boolean;
};

export interface CustomRouteProps extends RouteProps {
  computedMatch?: Match<any>;
  dataLoader?: DataLoader;
  drawerRoutes?: CustomRouteProps[];
  flagOn?: Flags;
  flagOff?: Flags;
  shownToEnterprise?: boolean;
  location?: HistoryLocation<LocationState>;
  redirectTo?: string;
  title?: string;
  withAuth?: boolean;
  isFirstVisit?: boolean;
  setIsFirstVisit?: Function;
  indexPage?: boolean; // Removes <meta name="robots" content="noindex,nofollow" /> from the page
  requireAccount?: boolean;
}

export interface WindowLocation {
  key?: unknown;
  pathname: string;
  search: string;
}

export interface TabbedRouteProps extends CustomRouteProps {
  createLocation: (mid: string) => string;
  order: number;
  requiresRole?: Role[];
}

export const CustomRoute = observer(
  ({
    component,
    computedMatch,
    dataLoader,
    location,
    redirectTo,
    title,
    withAuth,
    indexPage,
    ...rest
  }: CustomRouteProps): ReactElement => {
    const rootStore = useRootStore();
    const { authStore, userStore, errorStore } = rootStore;
    const { mutate: signOut } = useSignOut();
    const errorHandler = useErrorHandler();
    const history = useHistory();

    const { isLoading: isLoadingFeatureFlags } = useFeatureFlags();

    useEffect(() => {
      return () => {
        errorStore.clearErrors();
      };
    }, []);

    useEffect(() => {
      if (
        (withAuth && !authStore.token) ||
        (withAuth && userStore.user?.uid && !authStore.isLoggedIn)
      ) {
        signOut();
        history.push(ToSigninLocation());
      }
    }, [withAuth, authStore.token, userStore.user?.uid, history]);

    useEffect(() => {
      if (dataLoader && location && computedMatch && !isLoadingFeatureFlags) {
        dataLoader(rootStore, computedMatch, location).catch(errorHandler);
      }
    }, [dataLoader, location, computedMatch, isLoadingFeatureFlags]);

    const pageTitle = useMemo(() => {
      return getPageTitle(title);
    }, [title]);

    // Handle declarative redirects
    const { path } = rest;
    if (redirectTo) {
      if (typeof path === "string") {
        return <Redirect exact from={path} to={redirectTo} />;
      }
      if (Array.isArray(path)) {
        return (
          <>
            {path.map((p, index) => (
              <Redirect
                exact
                from={p}
                key={`routes--redirect--${index}`}
                to={redirectTo}
              />
            ))}
          </>
        );
      }
    }

    const Wrapper = rest.requireAccount ? AccountEnforcer : Fragment;

    return (
      <Wrapper>
        <Helmet>
          <title>{pageTitle}</title>
          {/** By default prevent all pages from being indexed */}
          {!indexPage && <meta name="robots" content="noindex,nofollow" />}
        </Helmet>
        <Route component={component} {...rest} />
      </Wrapper>
    );
  }
);

// Remember, this will redirect on first match so make sure the path to redirect to is before redirect declaration in the locations array
export const routes: CustomRouteProps[] = [
  {
    path: PublicProposalLocation,
    title: "View Team",
    withAuth: false,
    component: PublicProposalView,
  },
  {
    path: ProposalLocation,
    title: "View Team",
    withAuth: true,
    component: SingleProposalView,
    dataLoader: loadMissionStore,
  },
  ...AdminRoutes,
  ...ClientSettingsRoutes,
  ...MissionRoutes,
  {
    withAuth: false,
    path: SigninLocation,
    component: Signin,
    title: "Sign In",
    indexPage: IS_PROD,
  },
  {
    withAuth: false,
    path: SignupLocation,
    component: Signup,
    title: "Sign Up",
  },
  {
    withAuth: false,
    path: RegisterLocation,
    component: RegisterView,
    title: "Register",
    indexPage: IS_PROD,
  },
  {
    withAuth: false,
    path: SetNewPasswordLocation,
    component: SetNewPassword,
    title: "Set New Password",
  },
  {
    withAuth: true,
    path: OAuthLocation,
    component: OAuth,
    title: "Sign In with A.Team",
  },
  {
    withAuth: false,
    path: TeamProposalLocation,
    component: TeamProposal,
    title: "Sample Proposal",
  },
  {
    withAuth: false,
    path: ResetPasswordLocation,
    component: ResetPassword,
    title: "Reset Password",
  },
  {
    withAuth: false,
    path: EmailSentLocation,
    component: EmailSent,
    title: "Email Sent",
  },
  {
    withAuth: false,
    path: UseDesktopLocation,
    component: UseDesktop,
  },
  {
    withAuth: true,
    path: ShortlistLocation,
    component: ShortlistView,
    title: "Shortlist",
    requireAccount: true,
  },
  {
    withAuth: true,
    path: LegacyDiscoverLocation,
    component: LegacyDiscover,
    title: "Discover",
    requireAccount: true,
  },
  {
    withAuth: true,
    path: DiscoverSearchResultsLocation,
    component: SearchResults,
    title: "Search results",
    requireAccount: true,
  },
  {
    flagOn: Flags.TeamEngineDiscoveryFeeds,
    withAuth: true,
    path: DiscoverCategoryLocation,
    component: DiscoveryCategoryV2,
    title: "Discover",
    requireAccount: true,
  },
  {
    withAuth: true,
    path: DiscoverCategoryLocation,
    component: DiscoverCategory,
    title: "Discover",
    requireAccount: true,
  },
  {
    flagOn: Flags.TeamEngineDiscoveryFeeds,
    withAuth: true,
    path: DiscoverLocationV2,
    component: DiscoverV2,
    title: "Discover",
    requireAccount: true,
  },
  {
    withAuth: true,
    path: DiscoverLocation,
    component: Discover,
    title: "Discover",
    requireAccount: true,
  },
  {
    withAuth: true,
    path: InterviewScheduleLocation,
    component: InterviewSchedule,
    title: "Interview schedule",
    requireAccount: true,
  },
  {
    withAuth: false,
    path: BuilderLocation,
    component: BuilderProfile,
    title: "Builder",
    requireAccount: true,
    indexPage: IS_PROD,
  },
  {
    withAuth: true,
    path: FeedbackLocation,
    component: Feedback,
    title: "Feedback",
    requireAccount: true,
  },
  {
    withAuth: true,
    path: BuilderChatLocation,
    component: BuilderChat,
    title: "A.Team Chat",
    requireAccount: true,
  },
  {
    withAuth: false,
    path: CuratorLocation,
    component: CuratorChatRouter,
    title: "Team Formation AI",
    requireAccount: true,
  },
  {
    withAuth: false,
    path: UserReviewLocation,
    component: UserReview,
    title: "Leave feedback",
  },
  {
    withAuth: true,
    path: [HomeLocation, "/"],
    component: Home, // TODO: Rename to dashboard
    title: "Dashboard",
    requireAccount: true,
  },
];

const Routes = () => {
  const { data = [], isLoading } = useFeatureFlags();

  const allowedRoutes = useMemo(
    () => [...routes].filter((r) => isRouteAllowed(r, isLoading, data)),
    [data, isLoading]
  );

  return (
    <Suspense fallback={<LoadingFallback height="100vh" />}>
      <Switch>
        {allowedRoutes.map((route, index) => (
          <CustomRoute key={`routes--${index}`} {...route} />
        ))}
      </Switch>
    </Suspense>
  );
};

export default Routes;
