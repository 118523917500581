/** @jsxImportSource @emotion/react */
import { Column, Container, Row } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { AdminBaseLocation } from "Locations";
import { MissingBillingInfoWarning } from "components/BillingInformation";
import { BreadCrump } from "components/Layout/Header/BreadCrumbs";
import LoadingFallback from "components/LoadingFallback";
import {
  WORKSPACE_BANNER_HEIGHT,
  WORKSPACE_BODY_CLASS,
} from "components/WorkspaceBanner";
import { Flags } from "configs/featureFlags";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import useEducation, { EducationLocalStorageKeys } from "hooks/useEducation";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useLocalStorage } from "hooks/useLocalStorage";
import { observer } from "mobx-react";
import { useFeatureFlags } from "queries/featureFlags/useFeatureFlags";
import { FC, ReactNode, useEffect, useMemo } from "react";
import { useScreenClass } from "react-grid-system";
import { matchPath, useLocation } from "react-router-dom";
import { useRootStore } from "../../store";
import Drawer from "./Drawer";
import Header, { HeaderProps } from "./Header";
import SidebarEducationOverlay, {
  sidebarEducation,
} from "./Sidebar/SidebarEducationOverlay";
import UniversalSearch from "./UniversalSearch";

export const headerHeight = 64;

const getCSSRules: CSSRulesResolver<LayoutProps> = ({
  breakpoints,
  colors,
  disableScroll,
  removeBottomPadding,
  sidebar,
  ...props
}) => ({
  container: {
    padding: "0 !important",
  },
  main: {
    flex: "1 !important",
    height: "100vh",
    overflowX: "auto",
    overflowY: disableScroll ? "hidden" : "auto",
    position: "relative",
    paddingLeft: 0,
    [`@media (min-width: ${breakpoints.lg}px)`]: {
      paddingLeft: "0 !important",
    },
    [`body.${WORKSPACE_BODY_CLASS} &`]: {
      height: `calc(100vh - ${WORKSPACE_BANNER_HEIGHT}px) !important`,
    },
  },
  workspace: {
    ...(props.maxWidth && {
      maxWidth: "100%",
      margin: 0,
    }),
    position: "relative",
    boxSizing: "border-box",
    ...(!removeBottomPadding && {
      paddingBottom: 144,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        paddingBottom: 128,
      },
    }),
    ...(!props.fullWidth && {
      padding: 15,
      paddingTop: 24,
      ...(props.appearOnScroll && { marginTop: -1 * headerHeight }),
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        paddingTop: 24,
      },

      [`@media (min-width: ${breakpoints.md}px)`]: {
        padding: sidebar ? 24 : 40,
      },
    }),
    ...(props.noPadding && { padding: "0 !important", height: "100%" }),
  },
  overlay: {
    position: "fixed",
    display: "block",
    width: "calc(100% - 300px)",
    height: "100%",
    zIndex: 10000,
    background: colors.Grey[900],
    opacity: 0.5,
    top: 0,
    right: 0,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: "calc(100% - 260px)",
    },
    [`@media (min-width: ${breakpoints.lg}px)`]: { display: "none" },
  },
});

interface LayoutProps {
  appearOnScroll?: boolean;
  breadCrumps?: BreadCrump[];
  children: ReactNode;
  ctaBlock?: ReactNode;
  disableScroll?: boolean;
  fullWidth?: boolean;
  headerMainBlock?: ReactNode;
  headerProps?: HeaderProps;
  hideBackButton?: boolean;
  hideBillingWarnings?: boolean;
  hideHeader?: boolean;
  pageTitle?: ReactNode;
  preHeader?: ReactNode;
  sidebar?: ReactNode;
  solidOnScroll?: boolean;
  maxWidth?: number;
  removeBottomPadding?: boolean;
  noPadding?: boolean;
}

const Layout: FC<LayoutProps> = (props) => {
  const {
    appearOnScroll = false,
    breadCrumps,
    children,
    ctaBlock,
    disableScroll = false,
    fullWidth,
    headerMainBlock,
    headerProps = {},
    hideBackButton = false,
    hideBillingWarnings,
    hideHeader = false,
    pageTitle,
    preHeader,
    sidebar,
    solidOnScroll,
    maxWidth,
  } = props;
  const location = useLocation();

  const styles = useCSSRulesWithTheme(getCSSRules, {
    ...props,
    disableScroll,
    maxWidth,
  });
  const {
    uiStore: {
      sidebarOpen,
      closeSidebar,
      isMobile,
      starBuilderOverlay,
      setSeenAdvisorOverlay: setAdvisorOverlay,
      didSeeAdvisorOverlay,
      didSeeInviteCollaboratorOverlay,
      setInviteCollaboratorOverlay,
      inviterOverlayOffsetTop,
    },
    userStore: { user },
    accountsStore: { currentAccountId },
  } = useRootStore();

  const [hasSeenAdvisorEducation] = useLocalStorage(
    EducationLocalStorageKeys.has_seen_advisor_education,
    false
  );

  const [hasSeenCollaboratorsEducation] = useLocalStorage(
    EducationLocalStorageKeys.has_seen_collaborators_education,
    false
  );

  useEffect(() => {
    setAdvisorOverlay(hasSeenAdvisorEducation);
  }, [hasSeenAdvisorEducation]);

  useEffect(() => {
    setInviteCollaboratorOverlay(hasSeenCollaboratorsEducation);
  }, [hasSeenCollaboratorsEducation]);

  const isAdminRoutes = useMemo(
    () => Boolean(matchPath(location.pathname, { path: AdminBaseLocation })), // Match /admin/* routes
    [location]
  );

  const { isSuccess: featureFlagsLoaded } = useFeatureFlags();
  const { flagEnabled: withUniversalSearch } = useFeatureFlag(
    Flags.UniversalSearch
  );
  const { flagEnabled: withZeroFrictionSignUp } = useFeatureFlag(
    Flags.ZeroFrictionSignUp
  );

  const showStarBuilderOverlay =
    starBuilderOverlay &&
    !isAdminRoutes &&
    (isMobile ? !sidebarOpen : sidebarOpen);

  const showInviteOverlay = useMemo(() => {
    if (!didSeeAdvisorOverlay || didSeeInviteCollaboratorOverlay) {
      return false;
    }
    return withZeroFrictionSignUp && !isAdminRoutes && isMobile
      ? !sidebarOpen
      : sidebarOpen;
  }, [
    withZeroFrictionSignUp,
    isAdminRoutes,
    isMobile,
    sidebarOpen,
    didSeeAdvisorOverlay,
    didSeeInviteCollaboratorOverlay,
  ]);

  const currentEducationLabel = useMemo(() => {
    if (showStarBuilderOverlay) {
      return "Shortlisted builders";
    }
    if (showInviteOverlay) {
      return "Invite collaborators";
    }
    return undefined;
  }, [showStarBuilderOverlay, showInviteOverlay]);

  const showCurrentEducation = useMemo(() => {
    return (
      !!currentAccountId &&
      currentEducationLabel &&
      (showStarBuilderOverlay || showInviteOverlay)
    );
  }, [
    currentEducationLabel,
    showStarBuilderOverlay,
    showInviteOverlay,
    currentAccountId,
  ]);

  const sidebarEducationContent = useMemo(() => {
    return sidebarEducation.find(
      (item) => item.label === currentEducationLabel
    );
  }, [currentEducationLabel]);

  const { showEducation, completeEducation } = useEducation({
    shouldShowEducation: showCurrentEducation,
    completedEducationLSKey: sidebarEducationContent?.completedEducationLSKey,
  });

  const screenClass = useScreenClass();

  const showUniversalSearch = withUniversalSearch && !isAdminRoutes;

  useEffect(() => {
    if (["xs"].includes(screenClass)) {
      closeSidebar();
    }
  }, [screenClass]);

  if (!user || !featureFlagsLoaded) {
    return <LoadingFallback />;
  }

  return (
    <Container css={styles.container}>
      <Row noGutters style={{ margin: 0 }}>
        <Column style={{ minHeight: 0 }} xs={12}>
          {preHeader}
        </Column>
      </Row>
      <Row noGutters style={{ margin: 0 }}>
        {sidebar}
        <Column
          xs={12}
          css={css(styles.main, sidebar && styles.withSidebar)}
          data-id="layout-container"
        >
          {sidebar && sidebarOpen && (
            <div onClick={closeSidebar} css={styles.overlay} />
          )}
          {!hideHeader && (
            <Header
              appearOnScroll={appearOnScroll}
              breadCrumps={breadCrumps}
              ctaBlock={ctaBlock}
              fullWidth={fullWidth}
              hideBackButton={hideBackButton}
              mainBlock={headerMainBlock}
              pageTitle={pageTitle}
              solidOnScroll={solidOnScroll}
              {...headerProps}
            />
          )}
          <div css={styles.workspace}>{children}</div>

          {showEducation && sidebarEducationContent && (
            <SidebarEducationOverlay
              label={sidebarEducationContent.label}
              description={sidebarEducationContent.description}
              image={sidebarEducationContent.image}
              title={sidebarEducationContent.title}
              offsetTop={
                sidebarEducationContent.label === "Invite collaborators"
                  ? inviterOverlayOffsetTop
                  : undefined
              }
              onClose={() => {
                completeEducation();
                if (sidebarEducationContent.label === "Invite collaborators") {
                  setInviteCollaboratorOverlay(true);
                }
              }}
            />
          )}
          {!hideBillingWarnings && !isMobile && (
            <MissingBillingInfoWarning sidebar={Boolean(sidebar)} />
          )}
        </Column>
        <Drawer />
        {showUniversalSearch && <UniversalSearch />}
      </Row>
    </Container>
  );
};

export default observer(Layout);
