import { AuthStore } from "../store/Auth";
import serviceFetch from "./helpers";
import {
  UserReviewObject,
  UserReviewStatus,
  UserReviewUpdatePayload,
  VisibleUserReview,
} from "../models/UserReviews";
import { LinkedInRecommendationObject } from "models/LinkedInRecommendations";

const path = "user-reviews";

export const getUserReview = async (
  auth: AuthStore,
  reviewId: string
): Promise<UserReviewObject> => {
  return serviceFetch(auth, `${path}/${reviewId}`, null, "get");
};

export const getReviewStatus = async (
  auth: AuthStore,
  reviewId: string
): Promise<{ status: UserReviewStatus }> => {
  return serviceFetch(auth, `${path}/${reviewId}/status`, null, "get");
};

export const getRemainingReviews = async (
  auth: AuthStore,
  reviewId: string
): Promise<UserReviewObject[]> => {
  return serviceFetch(auth, `${path}/${reviewId}/remaining`, null, "get");
};

export const updateUserReview = async (
  auth: AuthStore,
  reviewId: string,
  payload: UserReviewUpdatePayload
): Promise<UserReviewObject> => {
  return serviceFetch(auth, `${path}/${reviewId}`, null, "put", payload);
};

export const getUserReviewsByUserId = async (
  uid: string,
  auth: AuthStore,
  options: {
    includeLinkedInRecommendations?: boolean;
  }
): Promise<
  (
    | (VisibleUserReview & { source: "ateam" })
    | (LinkedInRecommendationObject & { source: "linkedin" })
  )[]
> =>
  serviceFetch(
    auth,
    `proxy/${path}/${uid}`,
    {
      includeLinkedInRecommendations: options.includeLinkedInRecommendations,
    },
    "get"
  );
