import { useRootStore } from "store";
import { useMission } from "views/Mission/TeamSpecV2/MissionContext";
import { blankSpec, ServerSpecV3 } from "views/Mission/TeamSpecV2/types";
import { SpecV3 } from "../../types";
import { checkMissionCompleteness } from "views/Mission/TeamSpecV2/utils/spec";
import { useHistory } from "react-router-dom";
import { mapServerSpecToState } from "views/Mission/TeamSpecV2/utils/mappings/spec.mapping";
import { flushSync } from "react-dom";

export const useOnSpecSuccess = () => {
  const history = useHistory();
  const { missionSpecStore } = useRootStore();
  const {
    pristineMission,
    setMission,
    setShowErrors,
    setPristineMission,
    setReadonly,
    setSavedMissionData,
  } = useMission();

  return (data: Partial<ServerSpecV3>) => {
    // Wrap state updates in flushSync
    flushSync(() => {
      const savedId = data._id as string;
      setReadonly(true);
      setShowErrors(false);

      const currentUrl = location.pathname;
      const missionId = /^\/mission\/([^/]+)\/?/.exec(currentUrl)?.[1];

      if (missionId === "new" && savedId) {
        setMission({ ...blankSpec });
        setPristineMission({} as SpecV3);

        const newUrl = currentUrl.replace(missionId, savedId);
        history.push(newUrl);
      }

      // Update the mission state
      const draft = mapServerSpecToState(data as ServerSpecV3);
      setSavedMissionData(draft, true);
    });

    // Update missionSpecStore only if the title or status has changed
    // We want to avoid unnecessary re-refetches and re-renders
    if (
      data.title !== pristineMission?.title ||
      data.status !== pristineMission?.status
    ) {
      missionSpecStore.invalidateMissionCache();
    }
  };
};

export const useOnSpecError = (backupMessage?: string) => {
  const { uiStore } = useRootStore();

  return (error: unknown) => {
    if ((error as Error)?.message !== "Project details are incomplete") {
      uiStore.setApiErrorToast(error, backupMessage || "Something went wrong");
    }
  };
};

export const useValidateOrError = () => {
  const {
    uiStore,
    userStore: { user },
  } = useRootStore();
  const { mission, setShowErrors } = useMission();

  return () => {
    if (!mission) {
      throw new Error("Mission not found");
    }
    const errors = checkMissionCompleteness(mission, user)?.errors || [];
    if (errors.length) {
      setShowErrors(true);

      uiStore.setToast({
        text: "Some information is missing or invalid",
        type: "error",
      });

      throw new Error("Project details are incomplete");
    }

    setShowErrors(false);
  };
};

export function clearMissionLocalStorage() {
  try {
    const missionKeyPrefixes = ["mission-", "pristine-mission-"];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && missionKeyPrefixes.some((prefix) => key.startsWith(prefix))) {
        localStorage.removeItem(key);
      }
    }
  } catch (e) {
    console.warn("Failed to clear mission local storage", e);
  }
}
