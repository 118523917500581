import { FunctionComponent, useEffect } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { Location } from "history";
import tracking from "analytics";
import { CustomRouteProps, WindowLocation } from "Routes";
import { routes } from "Routes";
import { missionTabRoutes } from "routes/mission/tabRoutes";

const getRouteByPath = (location: string): CustomRouteProps | undefined => {
  return [...routes, ...missionTabRoutes].find(({ path }) => {
    const match = matchPath(location, {
      path,
    });

    return match && match.isExact;
  });
};

const AnalyticsProvider: FunctionComponent = ({ children }) => {
  const history = useHistory();

  const onLocationChange = (path: Location | WindowLocation) => {
    const { pathname, search } = path;
    const route = getRouteByPath(pathname);
    const title = route && route.title ? route.title : document.title;
    const isMissionRoute = !!window.location.pathname.match(/\/mission\//);
    const flowV2 = isMissionRoute;

    tracking.page(undefined, title, {
      path: pathname,
      url: window.location.href,
      title,
      search,
      flowV2,
    });
  };

  useEffect(() => {
    onLocationChange(window.location);
    history.listen(onLocationChange);
  }, [window.location]);

  return <>{children}</>;
};

export default AnalyticsProvider;
