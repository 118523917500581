import { ColorAlias } from "@a_team/ui-components";
import {
  AdminBaseLocation,
  AuthCuratorLocation,
  ClientSettingsBaseLocation,
  DiscoverLocation,
  HomeLocation,
  InterviewScheduleLocation,
  LegacyDiscoverLocation,
  NewMissionLocation,
  ShortlistLocation,
  ToMissionLocation,
} from "Locations";
import Navigation from "components/Sidebar/Navigation";
import {
  NavigationItem,
  NavigationItemProps,
} from "components/Sidebar/Navigation/NavigationItem";
import { Flags } from "configs/featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { observer } from "mobx-react";
import { useStarredBuilders } from "queries/discover/useStarredBuilders";
import { useLatestMissionTitles } from "queries/missionSpecs/useLatestMissionTitles";
import { useClientInterviews } from "queries/scheduling/useClientInterviews";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRootStore } from "store";
import plusIcon from "./assets/plus.svg";
import { MissionStatus } from "@a_team/models/dist/MissionObject";

const MainNavigation = () => {
  const {
    missionSpecStore,
    uiStore: {
      sidebarOpen,
      setAdminLastVisited,
      setIsCollaboratorInviteModalOpen,
    },
    userStore: { isAppInAdminMode },
  } = useRootStore();

  const { push } = useHistory();
  const location = useLocation();

  const { data, isLoading: shortlistBuildersLoading } = useStarredBuilders({});
  const countStarredUsers = useMemo(() => {
    if (shortlistBuildersLoading) {
      return 0;
    }
    return data?.size || 0;
  }, [data?.size, shortlistBuildersLoading]);
  const { data: missionTitles = [], dataUpdatedAt: missionTitlesUpdatedAt } =
    useLatestMissionTitles();
  const { flagEnabled: showUniversalSearch } = useFeatureFlag(
    Flags.UniversalSearch
  );
  const { flagEnabled: withZeroFrictionSignUp } = useFeatureFlag(
    Flags.ZeroFrictionSignUp
  );
  const { flagEnabled: showCurator } = useFeatureFlag(Flags.CuratorMainNav);
  const { flagEnabled: simplifiedTFAI } = useFeatureFlag(Flags.SimplifiedTFAI);

  const { data: clientInterviews } = useClientInterviews({});

  const colorAliases: ColorAlias[] = [
    "Baracus@500",
    "Faceman@600",
    "Green@600",
    "Hannibal@600",
    "Murdock@700",
    "Red@500",
  ];

  const items = useMemo(() => {
    const children = missionTitles
      .filter(
        ({ mid, missionStatus }) =>
          !!mid &&
          ![MissionStatus.Archived, MissionStatus.Ended].includes(
            (missionStatus || "") as MissionStatus
          )
      )
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .map(
        ({ title, mid }, i): NavigationItemProps => ({
          color: colorAliases[Math.floor(Math.random() * colorAliases.length)],
          iconName: "teamSign",
          iconSize: "md",
          id: mid || i.toString(),
          label: title || "",
          path: ToMissionLocation(mid!),
          enabled: true,
        })
      );

    const items: NavigationItemProps[] = [
      {
        id: "admin",
        label: "Admin Dashboard",
        iconName: "lock",
        handleClick: () => {
          setAdminLastVisited(location.pathname);
          push(AdminBaseLocation);
        },
        enabled: isAppInAdminMode,
      },
      {
        id: "curator",
        label: simplifiedTFAI ? "AI Search" : "Team Formation AI",
        iconName: "aiStar",
        path: AuthCuratorLocation,
        enabled: showCurator,
      },
      {
        id: "separator",
        label: "separator",
        divider: true,
        enabled: isAppInAdminMode,
      },
      {
        id: "legacy-discovery",
        label: "Discovery",
        path: LegacyDiscoverLocation,
        iconName: "previewOn",
        enabled: false,
      },
      {
        id: "discover",
        label: "Discover",
        path: DiscoverLocation,
        iconName: "discovery",
        enabled: true,
      },
      {
        id: "shortlist",
        label: "Shortlisted builders",
        iconName: "star",
        path: ShortlistLocation,
        enabled: countStarredUsers > 0,
        badge: String(countStarredUsers),
      },
      {
        action: () => {
          missionSpecStore.unsetMission();
          push(NewMissionLocation);
        },
        actionIcon: plusIcon,
        children: sidebarOpen ? children : undefined,
        enabled: true,
        iconName: "projects",
        id: "my-teams",
        initOpened: false,
        label: "My teams",
        path: HomeLocation,
        testid: "back-to-dashboard-button",
      },
      {
        handleClick: () => {
          setIsCollaboratorInviteModalOpen(true);
        },
        id: "invite",
        label: "Invite collaborators",
        iconName: "key",
        enabled: withZeroFrictionSignUp,
      },
      {
        id: "interviews",
        label: "Interviews",
        path: InterviewScheduleLocation,
        iconName: "comment",
        enabled: true,
        badge: clientInterviews ? String(clientInterviews.length) : "",
      },
      {
        id: "settings",
        label: "Settings",
        path: ClientSettingsBaseLocation,
        iconName: "settings",
        enabled: true,
      },
    ];

    return items;
  }, [
    missionTitlesUpdatedAt,
    sidebarOpen,
    countStarredUsers,
    isAppInAdminMode,
    showUniversalSearch,
    showCurator,
  ]);

  const enabledItems = items.filter((item) => item.enabled && !item.divider);

  return (
    <Navigation>
      {items.map((item) => (
        <NavigationItem
          {...item}
          totalCount={enabledItems.length}
          key={`navigation--main--${item.id}`}
        />
      ))}
    </Navigation>
  );
};

export default observer(MainNavigation);
