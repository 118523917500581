import { countries, continents } from "countries-list";

const countriesByContinent = () => {
  const countriesMap: Record<string, OptionObj[]> = {};
  Object.keys(continents).forEach((continent) => {
    countriesMap[continent] = [];
  });

  const sortedCountries = Object.entries(countries).sort((a, b) => {
    return a[1].name.localeCompare(b[1].name);
  });

  for (const [countryCode, country] of sortedCountries) {
    const { continent, name } = country;
    const typedContinent = continent as RegionRefs;
    countriesMap[continent].push({
      value: countryCode,
      label: name,
      region: typedContinent,
    });
  }
  return countriesMap;
};

export enum RegionLabels {
  AFRICA = "Africa",
  ASIA = "Asia",
  AUSTRALIA = "Australia and New Zealand",
  EUROPE = "Europe",
  NORTH_AMERICA = "North America",
  SOUTH_AMERICA = "South America",
}

export enum RegionRefs {
  AFRICA = "AF",
  ASIA = "AS",
  AUSTRALIA = "OC",
  EUROPE = "EU",
  NORTH_AMERICA = "NA",
  SOUTH_AMERICA = "SA",
}

export interface OptionObj {
  value: string;
  label: string;
  region: RegionRefs;
  disabled?: boolean;
}

export interface GroupObj {
  label: RegionLabels;
  ref: RegionRefs;
  options: OptionObj[];
}

function optionGroups() {
  const countriesList = countriesByContinent();
  return [
    {
      label: RegionLabels.NORTH_AMERICA,
      ref: RegionRefs.NORTH_AMERICA,
      options: countriesList.NA,
    },
    {
      label: RegionLabels.SOUTH_AMERICA,
      ref: RegionRefs.SOUTH_AMERICA,
      options: countriesList.SA,
    },
    {
      label: RegionLabels.EUROPE,
      ref: RegionRefs.EUROPE,
      options: countriesList.EU,
    },
    {
      label: RegionLabels.AFRICA,
      ref: RegionRefs.AFRICA,
      options: countriesList.AF,
    },
    {
      label: RegionLabels.ASIA,
      ref: RegionRefs.ASIA,
      options: countriesList.AS,
    },
    {
      label: RegionLabels.AUSTRALIA,
      ref: RegionRefs.AUSTRALIA,
      options: countriesList.OC,
    },
  ];
}

const OptionsGroup = optionGroups();

const allowedContinents = ["AF", "AS", "OC", "EU", "NA", "SA"];
export const selectAllCountries = () => {
  return Object.keys(countries).filter((countryCode) =>
    //@ts-ignore
    allowedContinents.includes(countries[countryCode].continent)
  );
};
export default OptionsGroup;
