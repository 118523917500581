export enum Flags {
  AiChatBuilderCardCta = "AiChatBuilderCardCta",
  AiChatConfidence = "AiChatConfidence",
  AiChatConversationStarter = "AiChatConversationStarter",
  AiChatDemo = "AiChatDemo",
  AssumeNonEnterprise = "AssumeNonEnterprise", // if true, will ignore the company stage and always return false when evaluating if the company is enterprise
  BlacklistCustomClientTOS = "blacklistCustomClientTOS",
  BuilderProfileBadges = "BuilderProfileBadges",
  BuilderProfileSharing = "BuilderProfileSharing",
  ChatbotExperiment = "chatbotExperiment",
  ClientArchitects = "clientArchitects",
  ClientRolesAndPerms = "ClientRolesAndPerms",
  ClientRolesAndPermsDebugger = "ClientRolesAndPermsDebugger",
  ContractsV2 = "ContractsV2", // if true, shows client app v2 iframe for contracts
  ContractsV2RemoveTermsOfService = "ContractsV2RemoveTermsOfService", // if true, terms of service are not shown
  CuratorShowSearchMoreButtons = "CuratorShowSearchMoreButtons",
  CustomRoleMarkups = "CustomRoleMarkups", // if true, will show workspace and custom role markup input fields
  CustomRoleQuestions = "CustomRoleQuestions",
  DirectInterviewBookingHourlyRates = "DirectInterviewBookingHourlyRates", // if true, shows hourly rate input fields on direct interview scheduling modal and client search
  DiscoveryBadges = "DiscoveryBadges",
  UniversalSearch = "UniversalSearch",
  SemanticSearch = "SemanticSearch",
  MissionSpecAI = "MissionSpecAI",
  MissionSpecFlowHomeV2 = "MissionSpecFlowHomeV2",
  MissionSpecReactQuery = "MissionSpecReactQuery",
  MonthlyRetainerRates = "MonthlyRetainerRates",
  NewOnboarding = "NewOnboarding",
  PriceGuidance = "priceGuidanceV1",
  PriceGuidanceV2 = "priceGuidanceV2",
  PriceGuidancePreview = "priceGuidanceExperimentalPreview",
  RoleSuggestedBuilders = "RoleSuggestedBuilders",
  RoleSuggestedBuildersLinkedIn = "RoleSuggestedBuildersLinkedIn",
  SearchAutocomplete = "SearchAutocomplete",
  ShowArchitects = "showArchitectsInClientApp",
  ShowClientBillingBanner = "ShowClientBillingBanner", // if true, will show the missing billing info warning
  ShowContractRatesInClientApp = "showContractRatesInClientApp",
  ShowInvoicesInClientApp = "showInvoicesInClientApp",
  ShowProposalRatesInClientApp = "showProposalRatesInClientApp",
  ShowRatesInClientApp = "showRatesInClientApp",
  ShowTeamPulse = "showTeamPulse",
  SpecContextBridge = "SpecContextBridge",
  StarEducation = "StarEducation",
  TeamAdvisor = "TeamAdvisor",
  TeamEngineDiscoveryFeeds = "TeamEngineDiscoveryFeeds",
  MissionSpecRoleStatusFilters = "MissionSpecRoleStatusFilters",
  GroupCuration = "GroupCuration",
  CuratorMainNav = "CuratorMainNav",
  NewTimesheets = "NewTimesheets",
  NewJobsClient = "NewJobsClient",
  ClientProfileSimilarBuilders = "ClientProfileSimilarBuilders",
  SingleColumnCurator = "SingleColumnCurator", // changes the curator layout to a single column, the builders will be shown in the same column as the chat
  CuratorBuilderCardV2 = "CuratorBuilderCardV2", //enables new curator builder card
  ZeroFrictionSignUp = "ZeroFrictionSignUp",
  BuilderRatings = "BuilderRatings", // enables user reviews feature
  TeamSummaryUI = "TeamSummaryUI", // manages the visibility of new UI changes related to the Team Summary
  TFAIDicoveryEntryPoint = "TFAIDicoveryEntryPoint", // If true, replaces the search bar with the TfAI discovery entry point
  SimplifiedTFAI = "SimplifiedTFAI", // If true, replaces the TfAI chat with a simplified version
  NewMonoRepoIframe = "NewMonoRepoIframe", // If true, uses the new monorepo iframe
  NetPromoterScore = "NetPromoterScore", // If true, display the Net Promoter Score in the review form
  HideTeamPulseEmailSettingEnabled = "HideTeamPulseEmailSettingEnabled", // If true, hides the Team Pulse email setting
  TeamSummaryEmail = "TeamSummaryEmail", // If true, enables the Team Summary email
  TimesheetSummariesViaLLM = "TimesheetSummariesViaLLM", // If true, uses LLM to generate timesheet summaries and display them
  LinkedInRecommendations = "LinkedInRecommendations", // If true, displays LinkedIn recommendations
}
