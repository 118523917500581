/** @jsxImportSource @emotion/react */

import analytics from "analytics";
import { CLIENT_INTERVIEW_SCHEDULED } from "analytics/events";
import { BookInterviewUser } from "components/BookInterviewButton";
import CalCom from "components/CalCom";
import ModalHeader from "components/Proposals/ReviewBuilders/ModalHeader";
import TrackModal from "components/TrackModal";
import useAccountAdvisor from "hooks/useAccountAdvisor";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { InterviewBooking } from "models/Interview";
import { useAccount } from "queries/accounts/useAccount";
import { useCreateClientInterview } from "queries/scheduling/useCreateClientInterview";
import { FC, useState } from "react";
import { CreateClientInterviewPayload } from "services/scheduling";
import { useRootStore } from "store";
import {
  IntroQuestionsForm,
  IntroQuestionsFormData,
} from "./IntroQuestionsForm";

export interface BookDirectInterviewModalProps {
  user: BookInterviewUser;
  isOpen: boolean;
  onClose: () => void;
  onInterviewBooked: () => void;
  minRate?: number;
}

const getCSSRules: CSSRulesResolver<{ progressWidth: string }> = ({
  colors,
  breakpoints,
  progressWidth,
}) => ({
  modal: {
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      minWidth: 1000,
      minHeight: 550,
      paddingBottom: 16,
      borderRadius: 4,
      opacity: 0.99,
      "&::after": {
        content: "''",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        height: 4,
        backgroundImage: `linear-gradient(90deg, ${colors.Faceman[600]} 25%, ${colors.Hannibal[500]} 62%, ${colors.Baracus[600]} 80%)`,
        width: progressWidth,
      },
    },
  },
  calendarWrapper: {
    paddingTop: 4,
    margin: "-36px -40px 0 -24px",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingTop: 16,
      margin: 0,
    },
  },
});

const EVENT_TYPE_SLUG = "discover-interview";

export const BookDirectInterviewModal: FC<BookDirectInterviewModalProps> = ({
  user,
  isOpen,
  onClose,
  onInterviewBooked,
  minRate,
}) => {
  const teamAdvisor = useAccountAdvisor();

  const [isIntroStepSubmitted, setIsIntroStepSubmitted] = useState(false);
  const [isBookingSubmitted, setIsBookingSubmitted] = useState(false);
  const [introQuestionsData, setIntroQuestionsData] =
    useState<IntroQuestionsFormData | null>(null);
  const styles = useCSSRulesWithTheme(getCSSRules, {
    progressWidth: isIntroStepSubmitted ? "100%" : "50%",
  });

  const {
    userStore: { user: authUser, displayName },
    accountsStore: { currentAccountId },
    uiStore,
  } = useRootStore();

  const { mutate: createClientInterview } = useCreateClientInterview();

  const { data: account } = useAccount(currentAccountId);

  const onIntroStepSubmit = (data: IntroQuestionsFormData) => {
    setIntroQuestionsData(data);
    setIsIntroStepSubmitted(true);
  };

  const calLink = `${user.username}/${EVENT_TYPE_SLUG}`;
  const onCalComConfirm = async (booking: InterviewBooking) => {
    const interviewData: CreateClientInterviewPayload = {
      builderId: user.uid,
      interviewRoleDescription: introQuestionsData?.description || "",
      clientMinHourlyRate:
        typeof introQuestionsData?.minRate === "number"
          ? Number(introQuestionsData?.minRate)
          : undefined,
      clientMaxHourlyRate:
        typeof introQuestionsData?.maxRate === "number"
          ? Number(introQuestionsData?.maxRate)
          : undefined,
      calComBookingId: booking.id,
      calComBookingUid: booking.uid,
      startDate: booking.startTime,
      endDate: booking.endTime,
      builderTimezone: booking.user?.timeZone,
    };
    createClientInterview(interviewData);
    analytics.track(CLIENT_INTERVIEW_SCHEDULED, {
      ...interviewData,
      clientId: authUser?.uid,
    });
    setIsBookingSubmitted(true);
    onInterviewBooked();
  };

  const onModalClose = () => {
    onClose();
    if (isBookingSubmitted) {
      setIsIntroStepSubmitted(false);
      setIsBookingSubmitted(false);
      uiStore.setToast({
        text: "The interview was requested",
        type: "success",
      });
    }
  };

  return (
    <TrackModal
      name="Direct Interview Booking Modal"
      isOpen={isOpen}
      onClose={onModalClose}
      css={styles.modal}
      shouldHideGradientStroke
    >
      <ModalHeader title={`Book interview with ${user.fullName}`} />

      {isIntroStepSubmitted ? (
        <div css={styles.calendarWrapper}>
          <CalCom
            key={calLink}
            calLink={calLink}
            onConfirm={onCalComConfirm}
            config={{
              name: displayName,
              email: authUser?.email || "",
              roleAndMissionTitle: "-",
              emailFrom: `A.Team <${teamAdvisor?.user.email}>`,
              emailCC: `${teamAdvisor?.user.email}`,
              "metadata[interviewRoleDescription]":
                introQuestionsData?.description || "",
              "metadata[companyName]": account?.clientCompany?.name || "",
            }}
          />
        </div>
      ) : (
        <IntroQuestionsForm
          user={user}
          onNext={onIntroStepSubmit}
          minBuilderRate={minRate}
        />
      )}
    </TrackModal>
  );
};
